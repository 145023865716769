import { NotCompliantModel } from "../not-compliant/not-compliant.model";

export class ToCheckModel extends NotCompliantModel {

    constructor(){
        super();
    }

    init(_currentEE: any){
        super.init(_currentEE)
        return this
    }
}