import { Component, OnInit ,EventEmitter, Output, Input, ViewChild} from '@angular/core';
import { DxDataGridComponent, DxDropDownBoxComponent } from "devextreme-angular";
import { ClientService } from 'app/services/client.service';
import { TranslateService } from '@ngx-translate/core';
import { QSE_LAST_LANGUE } from 'app/services/user.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-client-selecteur',
  templateUrl: './client-selecteur.component.html',
  styleUrls: ['./client-selecteur.component.scss']
})

export class ClientSelecteurComponent implements OnInit {

  @ViewChild('clientGrid') clientGrid: DxDataGridComponent;
  @ViewChild('dropBox') dropBox: DxDropDownBoxComponent;
  @Output() clientUpdated = new EventEmitter();
  @Input('selectedClient') selectedClient: any = 0;
  @Input('selection') selection : string;
  @Input('id') idDS : number;
  @Input('fetchMode') fetchMode : string;



  // datasources
  clientDataSource : any;
  nomClient : string = "Choisir un client";
  _gridBoxValue: number = 1;
  _gridSelectedRowKeys: number[] = [1];


  constructor(private  clientService: ClientService, public translate: TranslateService) {

    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
    this.translate.get(
      [
        'client-selecteur-nomClient*choisir-un-client'

      ]
    )
      .subscribe(val => {


      this.nomClient=val['client-selecteur-nomClient*choisir-un-client'];

      });


    this.clientDataSource = clientService.getRestDataSource();

  }

  ngOnInit() {

    if(this.selectedClient==0) this.selectedClient=undefined;

  }


  reset() {
    this.selectedClient = undefined;
  }

  ngAfterViewInit() {

    }

dropdown_syncGridSelection(event) {

  if (!this.clientGrid) return;

    if (event.value === null) {
     //  console.log("CLEARED !!!");
       this.nomClient = "";
      // this.clientUpdated.emit({id:0});
    }
  if (!this.clientGrid) {
     this.clientGrid.instance.clearSelection();
  } else {
    // If this is not commented, then selection is sysematically cleared
    ;// this.selectTreeList.instance.selectRowsByIndexes(this.treeListValue);
  }
}

  dropdown_gridItemSelectionChanged(event) {

    let multipleSelection;

    console.log(this.selection);

    if (typeof this.selection != "undefined" && this.selection.hasOwnProperty('mode')) {
      multipleSelection = _.isEqual(this.selection['mode'],  'multiple')
    } else {multipleSelection = false}

    if (!this.clientGrid) return;

    this.selectedClient = event.selectedRowKeys;

    this._gridBoxValue = this.selectedClient.length && this.selectedClient[0] || null;
    this._gridSelectedRowKeys = this.selectedClient;

   if (this.dropBox && this.dropBox.instance && event.selectedRowKeys.length>0) {

     if (!multipleSelection) {

      this.dropBox.instance.close();
     }
    this.nomClient = event.selectedRowsData[0].nom;
   }


  //  option pour ne pas appeller la fonction getSelectedRowsData qui fetch des données qui prennent énormément de temps
  //  if (this.fetchMode === 'noFetch') {

    if (!multipleSelection) {
      this.clientUpdated.emit( event.selectedRowsData[0]);
    } else {
      this.clientUpdated.emit( [event.selectedRowsData, this.idDS]);
    }



}



// prevent appel API inutile
dropdown_gridItemSelectionChangedBis(event) {

  const multipleSelection = _.isEqual(this.selection, Object({ mode: 'multiple' }))


  if (!this.clientGrid) return;



  if (!multipleSelection) {
    this.clientUpdated.emit( event.selectedRowsData[0]);
  } else {
    this.clientUpdated.emit( [event.selectedRowsData, this.idDS]);
  }
}

get gridBoxValue(): number {
  return this._gridBoxValue;
}

set gridBoxValue(value: number) {
  this._gridSelectedRowKeys = value && [value] || [];
  this._gridBoxValue = value;
}

get gridSelectedRowKeys(): number[] {
  return this._gridSelectedRowKeys;
}

set gridSelectedRowKeys(value: number[]) {
  this._gridBoxValue = value && value.length && value[0] || null;
  this._gridSelectedRowKeys = value;
}

gridBox_displayExpr(item){
  return item && item.nom;
}


checkSelection() {

  return typeof(this.selection) == undefined

}

}
