import { Injectable } from '@angular/core';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import { notify, notifyUpdated } from 'app/shared/util/util.component';
import { jsToSQLFilter, getHttpHeaders } from 'app/services/commons';
import { AppConfig } from 'app/app.config';
import { TranslateService } from '@ngx-translate/core';
import { QSE_LAST_LANGUE } from './user.service';
import { Subject } from 'rxjs/internal/Subject';

// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular8-app-with-ngx-translate
// https://stackoverflow.com/questions/46526014/angular-4-primeng-dropdown-translate-selected-option
export let _RefHistoEventDataSource = [
  { value: 1, label: "Créé" },
  { value: 2, label: "Modifié" },
  { value: 3, label: "Abrogé" },
  { value: 4, label: "Annulé" },
  { value: 5, label: "Transféré" }
];


export let _ExigenceEventDataSource = [
   { value: 1, label: "Créé" },
   { value: 2, label: "Modifié" },
   { value: 3, label: "Abrogé" }
 ];



let _ExigenceEventDataSourceCS = new CustomStore({
  loadMode: 'raw',
  key: 'value',
  load: function (loadOptions: any) : any{ return Promise.resolve(
[
  { value: 0, label: "Identique" },
  { value: 1, label: "Créée" },
  { value: 2, label: "Modifiée" },
  { value: 3, label: "Abrogée" }
]);
  }})



export class Reference {
  id: number;
  nom: string="";
  url: string;
  resume: string;

  estCode: boolean;
  code: string;
  profileType: boolean;
  controleReglementaire: boolean;
  reglementationEurope: boolean;
  versionConsolidee: boolean;
  dateCreation: string;
  dateEdition: string;


 nomVeille: string;
 nomThematique: string;
 nomSection: string;
 nomDomaine: string;
 nomSousDomaine: string;
 nomCode: string;
veille : number;
thematique: number;
section:number;
domaine:number=0;
sousdomaine:number=0;
}

@Injectable({  providedIn: 'root' })
export class ReferenceService {

  dataSource: any;
  tlabels: any = {};

  // Subject
  referenceSubject = new Subject<any>()

  // getAllreferences() {
  //   return this.http.get<any>(AppConfig.settings.api + "/api/reference/id_txts_source",  { headers: getHttpHeaders() }).toPromise().then(response => {

  //     return response;

  //   }).catch(function(err) {
  //     console.log(err);

  //     notify("getAllReferences ERREUR: " + err);
  //   });
  // }

  getAllreferences() {

    console.log('get all references');

    return this.http.get<any>(AppConfig.settings.api + "/api/reference/",  { headers: getHttpHeaders() }).toPromise();
  }

  getMaxRangExigences(id) {

    console.log(id);
    return this.http.get<any>(AppConfig.settings.api + "/api/reference/maxRang/" + id,   { headers: getHttpHeaders() }).toPromise();


  }

  // Taxonomie Reference Historique Exigence
  importTRHE(values:any) {

   //  return this.http.get<any>(AppConfig.settings.api +"/api/reference/importTRHE"  + encodeURIComponent(noeud_id), { headers: getHttpHeaders()}).toPromise();
    return  this.http.post<any>(AppConfig.settings.api +"/api/reference/importTRHE", values, { headers: getHttpHeaders()}).toPromise().then(response => {
      return response;

    }).catch(function(err) {
      console.log(err);

      notify("importTRHE ERREUR: " + err);
  });
  }

  //Exigence
  importE(values:any) {
    //  return this.http.get<any>(AppConfig.settings.api +"/api/reference/importTRHE"  + encodeURIComponent(noeud_id), { headers: getHttpHeaders()}).toPromise();
     return  this.http.post<any>(AppConfig.settings.api +"/api/reference/importE", values, { headers: getHttpHeaders()}).toPromise().then(response => {
       return response;
     }).catch(function(err) {
       notify("importE ERREUR: " + err);
   });
   }




   NLP_extractSyntagmes(values:any) {
        return  this.http.post<any>(AppConfig.settings.api +"/xapi/syntagmes/", values, { headers: getHttpHeaders()}).toPromise().then(response => {
       return response;
     }).catch(function(err) {
       notify("NLP_extractSyntagmes ERREUR: " + err);
   });
   }


  constructor(private http: HttpClient, public translate: TranslateService) {

    let SERVICE_URL = AppConfig.settings.api + "/api/reference/";


    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
    this.translate.get(
      [
        'common-term*conforme',
        'common-term*non conforme',
        'common-term*à vérifier',
        'common-term*à évaluer',
        'common-term*sans objet',
        'common-term*déjà évalué',
        'statistiques-abrev*pour info',
        'common-term*statut-AE',
        'common-term*statut-AV',
        'common-term*statut-C',
        'common-term*statut-I',
        'common-term*statut-NC',
        'common-term*statut-SO',
        'services-common-notify*màj',

        'common-term*toutes',
        'common-term*Créé',
        'common-term*Modifié',
        'common-term*Abrogé',
        'common-term*Annulé',
        'common-term*Transféré',
        'common-term*Créée',
        'common-term*Modifiée',
        'common-term*Abrogée',

      ]
      )
        .subscribe(val => {
          this.tlabels = val;

          _RefHistoEventDataSource[0].label = val['common-term*Créé'];
          _RefHistoEventDataSource[1].label = val['common-term*Modifié'];
          _RefHistoEventDataSource[2].label = val['common-term*Abrogé'];
          _RefHistoEventDataSource[3].label = val['common-term*Annulé'];
          _RefHistoEventDataSource[4].label = val['common-term*Transféré'];

          _ExigenceEventDataSource[0].label = val['common-term*Créée'];
          _ExigenceEventDataSource[1].label = val['common-term*Modifiée'];
          _ExigenceEventDataSource[2].label = val['common-term*Abrogée'];

          _ExigenceEventDataSourceCS.load().then( r=>{
            // TODO
            // r[0].label = val['common-term*toutes'];
            r[0].label = 'Identique';
            r[1].label = val['common-term*Créée'];
            r[2].label = val['common-term*Modifiée'];
            r[3].label = val['common-term*Abrogée'];
            });
  
      });

    let zis = this;


    this.dataSource = new CustomStore(
      {
      key: "id",

      load: function(loadOptions:any)  : any {

        var filter =  "?filter=1=1";

        if(AppConfig.settings.trace)  console.log("ReferenceService ", loadOptions);

        if(AppConfig.settings.trace)  console.log("ReferenceService filter", loadOptions.filter);


        if(loadOptions.filter) {
            filter = "?filter="+  encodeURIComponent(jsToSQLFilter(loadOptions.filter));
       }
       if(loadOptions.skip) {
        filter = filter + "&skip=" + loadOptions.skip;
        }
        else
        filter = filter + "&skip=0";

      if(loadOptions.take) {
        filter = filter + "&take=" + loadOptions.take;
        }

        if(loadOptions.sort) {
          var usort = "";
          for(var i=0; i<loadOptions.sort.length; i++) {
            var field = "";
            if(loadOptions.sort[i].selector == "nomReference")
            field = "n.reference.nom";
            else
            field =  loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC") ;
            if(i+1<loadOptions.sort.length)
              usort = usort+ ",";
          }
          filter = filter  + "&sort="+  encodeURIComponent(usort);
          }

          if(AppConfig.settings.trace)  console.log(" ReferenceService load: calling url: "  + SERVICE_URL   + filter );

      return http.get<any>( SERVICE_URL   + filter, { headers: getHttpHeaders()} ).toPromise()
         .then(response => {
          if(AppConfig.settings.trace) console.log("---------------> ReferenceService SERVER RESPONSE: ",response);
             return response;

         });
       },

      // totalCount: function(loadOptions:any) { return 0;},

       byKey: function(key) {
        if(AppConfig.settings.trace)  console.log("byKey: "  + SERVICE_URL + "/" + encodeURIComponent(key));
           return http.get(SERVICE_URL   + encodeURIComponent(key), { headers: getHttpHeaders()}).toPromise();
       },
       insert: async function(values:any) {

        if(AppConfig.settings.trace)  console.log("CREATION: ", values);

          // Need to create user (password, email)

          if(AppConfig.settings.trace)  console.log("insert: post "  + SERVICE_URL );
           return http.post<any>(SERVICE_URL, values, { headers: getHttpHeaders()}).toPromise().then(response => {
               values.id = response.key;

              return {
                  values: response.values,
                  key: response.key
              }
          }).catch(function(err) {
            notify("ERREUR: " + err);
         });
       },
       update: function(key, values) {
         if(key==undefined) return;
        if(AppConfig.settings.trace)  console.log("update put: "  + SERVICE_URL + "/" + encodeURIComponent(key));
        if(AppConfig.settings.trace) console.log("update values:", values);
           return http.put<any>(SERVICE_URL   + encodeURIComponent(key), values, { headers: getHttpHeaders()}).toPromise() .then(response => {

              notifyUpdated()
                 return {
                     result: response.result,
                     key: response.key
                 }
             });

       },
       remove: function(key) {
        // Need to refresh cache after that ?
        if(AppConfig.settings.trace) console.log("remove delete: "  + SERVICE_URL + "/" + encodeURIComponent(key));
        return http.delete<any>(SERVICE_URL   + encodeURIComponent(key), { headers: getHttpHeaders()}).toPromise();
    }
   }



    );

 }

  getRestDataSource() {
    return this.dataSource;
  }

  getEvenementReferenceDataSource() {
    return _RefHistoEventDataSource;
  }

  getEvenementExigenceDataSource() {
    return _ExigenceEventDataSource;
  }


  getEvenementExigenceDataSourceCS() {
    return _ExigenceEventDataSourceCS;
  }

  getReferencesNames() {
    return this.http.get<any>(AppConfig.settings.api + "/api/references_names/", { headers: getHttpHeaders() }).toPromise();
  }

  getReferencesCodesVeillesNames() {
    return this.http.get<any>(AppConfig.settings.api + "/api/references_codes_veilles/", { headers: getHttpHeaders() }).toPromise();
  }

  getExigencesNames(referenceID : string) {
    return this.http.get<any>(AppConfig.settings.api + "/api/exigences_names/" + encodeURI(referenceID),  { headers: getHttpHeaders() }).toPromise();
}

}
