<div class="auditnav">
    <span style="margin-top: 5px" class="auditTitle">&nbsp;&nbsp;{{'detail-view-with-nav-span*Audit sélectionné'|translate}}</span>
    <dx-select-box
      [hint]="'detail-view-with-nav-hint*choix-audit'|translate"
      [dataSource]="dataSource"
      valueExpr="id" width="350"
      displayExpr="description"
      [searchEnabled]="true"
      [(value)]="value"
      (onValueChanged)="onSelectedAuditChanged($event)">
    </dx-select-box>              
</div>