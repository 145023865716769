import { ToEvaluateModel } from './to-evaluate/to-evaluate.model'
import { CompliantModel } from './compliant/compliant.model'
import { WithoutObjectModel } from './without-object/without-object.model'
import { NotCompliantModel } from './not-compliant/not-compliant.model'
import { ToCheckModel } from './to-check/to-check.model'

export class EvaluationExigenceModel {

    id: number 
    statut: number
    initialStatut: number

    toEvaluateModel: ToEvaluateModel
    compliantModel: CompliantModel 
    notCompliantModel: NotCompliantModel
    withoutObjectModel: WithoutObjectModel 
    toCheckModel: ToCheckModel

    notifiyResponsible: boolean = false

    userActions: string[] = []
    isAdminAsUser: boolean = false

    constructor(){}

    init(_currentEE: any, linkedForm: any){

        if(linkedForm !== undefined){
            _currentEE = linkedForm.eeModel.flatten()
        }

        this.id = _currentEE.id
        this.statut = _currentEE.statut
        this.initialStatut = _currentEE.statut

        this.toEvaluateModel = new ToEvaluateModel()
        this.compliantModel = new CompliantModel()
        this.compliantModel.notes = _currentEE.notes
        this.notCompliantModel = new NotCompliantModel()
        this.notCompliantModel.notes = _currentEE.notes
        this.withoutObjectModel = new WithoutObjectModel()
        this.withoutObjectModel.notes = _currentEE.notes
        this.toCheckModel = new ToCheckModel()
        this.toCheckModel.notes = _currentEE.notes

        switch(this.statut){
            case 1:
                this.toEvaluateModel.init(_currentEE)
                break;
            case 2: 
                this.compliantModel.init(_currentEE)
                break;
            case 3: 
                this.notCompliantModel.init(_currentEE)
                break;
            case 4: 
                this.withoutObjectModel.init(_currentEE)
                break;
            case 5: 
                this.toCheckModel.init(_currentEE)
                break;
            default:
                break;
        }

        return this
    }

    flatten(){
        let model_: any

        switch(this.statut){
            case 1:
                model_ = { ...new CompliantModel(), ...new NotCompliantModel(), ...new WithoutObjectModel(), ...new ToCheckModel(), ...this.toEvaluateModel}
                break;
            case 2: 
                model_ = { ...new ToEvaluateModel(), ...new NotCompliantModel(), ...new WithoutObjectModel(), ...new ToCheckModel(), ...this.compliantModel}
                break;
            case 3: 
                model_ = { ...new ToEvaluateModel(), ...new CompliantModel(), ...new WithoutObjectModel(), ...new ToCheckModel(), ...this.notCompliantModel}
                break;
            case 4: 
                model_ = { ...new ToEvaluateModel(), ...new CompliantModel(), ...new NotCompliantModel(), ...new ToCheckModel(), ...this.withoutObjectModel}
                break;
            case 5: 
                model_ = { ...new ToEvaluateModel(), ...new CompliantModel(), ...new NotCompliantModel(), ...new WithoutObjectModel(), ...this.toCheckModel}
                break;
            default:
                model_ = {}
                break;
        }
        model_.id = this.id
        model_.statut = this.statut
        model_.userActions = this.userActions

        return model_
    }

    isToEvaluate(){
        return this.statut == 1
    }

    isCompliant() {
        return this.statut == 2
    }

    isNotCompliant(){
        return this.statut == 3
    }

    isWithoutObject() {
        return this.statut == 4
    }

    isToCheck(){
        return this.statut == 5
    }

    getResponsable(cas:number) {
        if(cas==3) // NC
        return this.notCompliantModel.responsable;
        if(cas==5)
        return this.toCheckModel.responsable;
    }
    
}
