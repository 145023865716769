
        <dx-drop-down-box #dropBox 
        [dropDownOptions]="{width:500 }"
                [deferRendering]="true"
              [dataSource]="serviceDataSource"
              [(value)]="selectedService"
              valueExpr="id"
              [displayExpr]="gridBox_displayExpr"
              placeholder="Choisir un service visé"
              [showClearButton]="true"
              (onValueChanged)="dropdown_syncGridSelection($event)"  >

              <div *dxTemplate="let data of 'content'">
                <dx-data-grid #serviceGrid  id="serviceGrid"  height="550px" 
                      [dataSource]="serviceDataSource"
                     
                     
                      [wordWrapEnabled]="true"
                      [columnAutoWidth]="true"
                      cacheEnabled="true"     
                      [hoverStateEnabled]="true"

                      [(selectedRowKeys)]="gridSelectedRowKeys"
                      (onSelectionChanged)="dropdown_gridItemSelectionChanged($event)" 
                      (onContentReady)="$event.component.selectRowsByIndexes(treeBoxValue)" >
                  
                      <dxo-scrolling mode="standard">   </dxo-scrolling>
                  
                      <dxo-remote-operations 
                          [filtering]="true" 
                          [paging]="true" 
                          [sorting]="true" 
                          [grouping]="false"></dxo-remote-operations>
                        <dxo-paging [pageSize]="10" [enabled]="true"></dxo-paging>
                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[ 10, 20, 30]"></dxo-pager>

                      <dxo-search-panel [visible]="true"></dxo-search-panel>
                      <dxo-selection mode="single"  ></dxo-selection>
                      <dxi-column dataField="nomService" caption="Nom" [allowSorting]="true" ></dxi-column>
      
                   
                     </dx-data-grid>
              </div>
          </dx-drop-down-box>
   