import { Component, OnInit ,EventEmitter, Output, Input, ViewChild} from '@angular/core';
import { DxDataGridComponent, DxDropDownBoxComponent } from "devextreme-angular";
import { AdministrateurService } from 'app/services/administrateur.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-admin-selecteur',
  templateUrl: './admin-selecteur.component.html',
  styleUrls: ['./admin-selecteur.component.scss']
})
export class AdminSelecteurComponent implements OnInit {


  @ViewChild('adminGrid') adminGrid: DxDataGridComponent;
  @ViewChild('dropBox') dropBox: DxDropDownBoxComponent;
  @Output() adminUpdated = new EventEmitter();
  @Input('selectedAdmin') selectedAdmin: any = 0;  



  // datasources
  adminDataSource : any;
 

  nomClient : string = "Choisir un administrateur";
  _gridBoxValue: number = 1;
  _gridSelectedRowKeys: number[] = [1];



  constructor(private  adminService: AdministrateurService, public translate: TranslateService) { 
    this.adminDataSource = adminService.getActifsRestDataSource();
  }

  ngOnInit() {
    if(this.selectedAdmin==0) this.selectedAdmin=undefined;
  }

  ngAfterViewInit() {
  
    }
  
dropdown_syncGridSelection(event) {
 
  if (!this.adminGrid) return;

    if (event.value === null) {
   
       this.nomClient = "";
      // this.clientUpdated.emit({id:0});
    }
  if (!this.adminGrid) {
     this.adminGrid.instance.clearSelection();
  } else {
    // If this is not commented, then selection is sysematically cleared
    ;// this.selectTreeList.instance.selectRowsByIndexes(this.treeListValue);
  }
}

  dropdown_gridItemSelectionChanged(event) {
    if (!this.adminGrid) return;
   // console.log("dropdown_gridItemSelectionChanged", event);
    this.selectedAdmin = this.adminGrid.instance.getSelectedRowKeys();

    this._gridBoxValue = this.selectedAdmin.length && this.selectedAdmin[0] || null;
    this._gridSelectedRowKeys = this.selectedAdmin;

   if (this.dropBox && this.dropBox.instance && event.selectedRowKeys.length>0) {
     this.dropBox.instance.close();
    this.nomClient = event.selectedRowsData[0].nom;
   }
   this.adminUpdated.emit( this.adminGrid.instance.getSelectedRowsData()[0]);
  }


get gridBoxValue(): number {
  return this._gridBoxValue;
}

set gridBoxValue(value: number) {
  this._gridSelectedRowKeys = value && [value] || [];
  this._gridBoxValue = value;
}

get gridSelectedRowKeys(): number[] {
  return this._gridSelectedRowKeys;
}

set gridSelectedRowKeys(value: number[]) {
  this._gridBoxValue = value && value.length && value[0] || null;
  this._gridSelectedRowKeys = value;
}

gridBox_displayExpr(item){
  return item && item.nom;
}

}
