import { NgModule } from '@angular/core';
import { CommonModule, Location } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { DxFormModule, DxSelectBoxModule, DxDropDownBoxModule, DxTreeListModule, DxDataGridModule, DxRadioGroupModule, DxButtonModule, DxNumberBoxModule, DxCheckBoxModule, DxScrollViewModule, DxFileUploaderModule, DxPieChartModule, DxProgressBarModule, DxTabPanelModule, DxTextBoxModule, DxTooltipModule, DxTextAreaModule, DxHtmlEditorModule, DxRangeSelectorModule, DxRangeSliderComponent, DxRangeSliderModule } from 'devextreme-angular';
import { ClientSelecteurComponent } from './client-selecteur/client-selecteur.component';
import { SiteSelecteurComponent } from './site-selecteur/site-selecteur.component';
import { SiteService } from 'app/services/site.service';
import { ProgressionService } from 'app/services/progression.service';
import { EvaluationExigenceFormService } from './exigence/evaluation/form/form.service'
import { AdminSelecteurComponent } from './admin-selecteur/admin-selecteur.component';
import { EvaluationExigenceFormComponent } from './exigence/evaluation/form/form.component';
import { EvaluationExigenceStatusComponent } from './exigence/evaluation/form/status/status.component';
import { CommonComponent } from './common/common.component';
import { CompliantComponent } from './exigence/evaluation/form/compliant/compliant.component';
import { WithoutObjectComponent } from './exigence/evaluation/form/without-object/without-object.component';
import { ResponsableSelecteurComponent } from './responsable-selecteur/responsable-selecteur.component';
import { ServiceSelecteurComponent } from './service-selecteur/service-selecteur.component';
import { ToEvaluateComponent } from './exigence/evaluation/form/to-evaluate/to-evaluate.component';
import { ToCheckComponent } from './exigence/evaluation/form/to-check/to-check.component';
import { NotCompliantComponent } from './exigence/evaluation/form/not-compliant/not-compliant.component';
import { EvaluationExigenceDetailedViewComponent } from './exigence/evaluation/detailed-view/detailed-view.component';
import { TaxoComponent } from './taxo/taxo.component';
import { AngularSplitModule } from 'angular-split';
import { DocUploaderComponent } from './doc-uploader/doc-uploader.component';
import { MotifComponent } from './motif/motif/motif.component';
import { AuditSelectorComponent } from './audit-selector/audit-selector.component';
import { PieChartComponent } from './exigence/evaluation/pie-chart/pie-chart/pie-chart.component';
import { ProgressionComponent } from './progression/progression.component';
import { UtilComponent } from './util/util.component';
import { MultiProgressionComponent } from './multi-progression/multi-progression.component';
import { XtractorComponent } from './xtractor/xtractor.component';
import { StatusSelectorComponent } from './status-selector/status-selector.component';
import { ConnectAsComponent } from './connect-as/connect-as.component';
import { ConnectAsService } from './connect-as/connect-as.service';
import { GroupeSelecteurComponent } from './groupe-selecteur/groupe-selecteur.component';
import { SitesSelecteurComponent } from './sites-selecteur/sites-selecteur.component';
import { ClientsSelecteurComponent } from './clients-selecteur/clients-selecteur.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { CollapsiblePanelComponent } from './collapsible-panel/collapsible-panel.component';
import { RefNavComponent } from './ref-nav/ref-nav.component';



@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        ToggleFullscreenDirective,
        NgbModule,
        TranslateModule,
        CommonComponent,
        ClientSelecteurComponent,
        GroupeSelecteurComponent,
        SiteSelecteurComponent,
        AdminSelecteurComponent,
        EvaluationExigenceFormComponent,
        EvaluationExigenceDetailedViewComponent,
        TaxoComponent,
        DocUploaderComponent,
        DxFileUploaderModule,
        MotifComponent,
        AuditSelectorComponent,
        PieChartComponent,
        ProgressionComponent,
        UtilComponent,
        MultiProgressionComponent,
        XtractorComponent,
        StatusSelectorComponent,
        ConnectAsComponent,
        SitesSelecteurComponent,
        ClientsSelecteurComponent,
        CollapsiblePanelComponent,
        RefNavComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        DxFormModule,
        DxSelectBoxModule,
        DxDropDownBoxModule,
        DxDataGridModule,
        DxRadioGroupModule,
        DxButtonModule,
        DxNumberBoxModule,
        DxCheckBoxModule,
        DxScrollViewModule,
        TranslateModule,
        AngularSplitModule,
        DxTreeListModule,
        DxFileUploaderModule,
        DxPieChartModule,
        DxProgressBarModule,
        DxTabPanelModule,
        DxScrollViewModule,
        DxTextBoxModule,
        DxTooltipModule,
        DxTextAreaModule,
        DxHtmlEditorModule,
        DxRangeSliderModule,
        DxDropDownBoxModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        ToggleFullscreenDirective,
        ClientSelecteurComponent,
        SiteSelecteurComponent,
        AdminSelecteurComponent,
        ResponsableSelecteurComponent,
        ServiceSelecteurComponent,
        EvaluationExigenceFormComponent,
        EvaluationExigenceStatusComponent,
        CommonComponent,
        CompliantComponent,
        WithoutObjectComponent,
        ToEvaluateComponent,
        ToCheckComponent,
        NotCompliantComponent,
        EvaluationExigenceDetailedViewComponent,
        TaxoComponent,
        DocUploaderComponent,
        MotifComponent,
        AuditSelectorComponent,
        PieChartComponent,
        ProgressionComponent,
        UtilComponent,
        MultiProgressionComponent,
        XtractorComponent,
        StatusSelectorComponent,
        ConnectAsComponent,
        GroupeSelecteurComponent,
        SitesSelecteurComponent,
        ClientsSelecteurComponent,
        SanitizeHtmlPipe,
        CollapsiblePanelComponent,
        RefNavComponent
    ],
    providers: [ 
        SiteService,
        EvaluationExigenceFormService,
        ProgressionService,
        ConnectAsService
    ]
})
export class SharedModule { }
