import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EvaluationConformiteService } from 'app/services/evaluation-conformite.service';

@Component({
  selector: 'app-audit-selector',
  templateUrl: './audit-selector.component.html',
  styleUrls: ['./audit-selector.component.scss']
})
export class AuditSelectorComponent implements OnInit {

  @Input() dataSource: any
  @Input() value: any

  constructor(private auditService: EvaluationConformiteService) { }

  ngOnInit(): void {
  }

  onSelectedAuditChanged(e:any) {
    this.auditService.selectedAuditSubject.next(e.value)
  }

}
