import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QSE_LAST_LANGUE } from 'app/services/user.service';

@Component({
  selector: 'app-ee-detailed-view',
  templateUrl: './detailed-view.component.html',
  styleUrls: ['./detailed-view.component.scss']
})
export class EvaluationExigenceDetailedViewComponent implements OnInit {

  @Input() currentUsage: any
  @Input() currentStatut: string
  @Input() commentaireContent: string
  @Input() currentExigence: any
  @Input() titreNoeud: any
  @Input() cssNoeud: any

  constructor( public translate: TranslateService ) { }

  tlabels : any = {};

  ngOnInit(): void {

    //  let lang=sessionStorage.getItem(QSE_LAST_LANGUE);
    //  console.log("stats.component ngOnInit lang="+lang);
    //  this.translate.use(lang?lang: "fr");
  
      this.translate.get(
        [
          'common-term*Créé',
          'common-term*Modifié',
          'common-term*Abrogé',
          'common-term*Annulé',
          'common-term*Transféré',
          'detailed-view-msg*transfere-depuis',
          'detailed-view-msg*abroge-depuis',
          'detailed-view-msg*annule-depuis',
          'detailed-view-msg*modifie-depuis',
          'detailed-view-msg*applicable-depuis'
        ]
        )
        .subscribe(val => {
          this.tlabels.cree = val['common-term*Créé'];
          this.tlabels.modifie = val['common-term*Modifié'];
          this.tlabels.abroge = val['common-term*Abrogé'];
          this.tlabels.annule = val['common-term*Annulé'];
          this.tlabels.transfere = val['common-term*Transféré'];
          this.tlabels.applicabledepuis = val['applicable-depuis'];
          this.tlabels.modifiedepuis = val['common-term*modifie-depuis'];
          this.tlabels.abrogedepuis = val['common-term*abroge-depuis'];
          this.tlabels.annuledepuis = val['common-term*annule-depuis'];
          this.tlabels.transferedepuis = val['common-term*transfere-depuis'];
          
        });

        
    if(this.currentStatut === undefined){
      if (this.currentUsage.historique) {
        switch (this.currentUsage.historique.evenement) {
          case 1: /* creation */
            {
              // TODO: si this.currentUsage.historique.creeParLuiMeme alors adapter le message.
              this.currentStatut = this.tlabels.cree+" " + this.currentUsage.historique.description + (!(this.currentUsage.historique.dateApplicabilite == "1970-01-01") ? "" : this.tlabels.applicabledepuis + this.currentUsage.historique.dateApplicabilite);
            }
            break;
          case 2: /* modif */
            {
              this.currentStatut = this.tlabels.modifie+" " + this.currentUsage.historique.description + this.tlabels.modifiedepuis + this.currentUsage.historique.dateApplicabilite;
            }
            break;
          case 3: /* abroge */
            {
              this.currentStatut = this.tlabels.abroge+" " + this.currentUsage.historique.description + this.tlabels.abrogedepuis + this.currentUsage.historique.dateApplicabilite;
            }
            break;
          case 4: /* annulé */
            {
              this.currentStatut = this.tlabels.annule +" " + this.currentUsage.historique.description + this.tlabels.annuledepuis  + this.currentUsage.historique.dateApplicabilite;
            }
            break;
          case 5: /* transfert */
            {
              this.currentStatut = this.tlabels.transfere + " " + this.currentUsage.historique.description + this.tlabels.transferedepuis + this.currentUsage.historique.dateApplicabilite;
            }
            break;
          default:
            this.currentStatut = undefined;

        }
      }
    }
  }

}
