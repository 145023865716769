import { APP_INITIALIZER } from '@angular/core';
import { AppConfig } from './app.config';

import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { FormsModule }   from '@angular/forms';
import { AngularSplitModule } from 'angular-split';

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth.guard';
import { AdminAuthGuard } from './shared/auth/admin-auth.guard';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


// Switch Between Themes at Runtime https://js.devexpress.com/Documentation/Guide/Themes_and_Styles/Predefined_Themes/
// Custom theme : https://community.devexpress.com/blogs/javascript/archive/2019/04/03/devextreme-cli-custom-theme-generation-v18-2.aspx

//import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
//import { myRxStompConfig } from './my-rx-stomp.config';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

import {
  DxDataGridComponent,
  DxTreeListModule,
  DxDataGridModule,
  DxButtonModule,
  DxTreeViewModule,
  DxSparklineModule,
  DxTemplateModule,
  DxListModule,
  DxToolbarModule,
  DxSelectBoxModule,
  DxCheckBoxModule,
  DxContextMenuModule,
  DxFileUploaderModule,
  DxSwitchModule ,
  DxDropDownBoxModule,  
  DxPopoverModule,
  DxPopupModule} from 'devextreme-angular';
import { AdminChatService } from 'app/services/admin-chat.service';
import { UserService } from 'app/services/user.service';

import { AdministrateurService } from 'app/services/administrateur.service';
import { TaxonomieService } from 'app/services/taxonomie.service';
import { MessageBusService } from 'app/services/message-bus.service';
import { AdministrateurNotificationsService } from 'app/services/administrateur-notifications.service';

import { DatePipe } from '@angular/common';


import * as $ from 'jquery';
import  'devextreme/integration/jquery';
import { SiteChangeService } from './site-change.service';
import { SearchEngineComponent } from './search-engine/search-engine.component';
import { RouteReuseStrategy } from '@angular/router';
import {CacheRouteReuseStrategy} from './cache-route-reuse-strategy'
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}


/*
export function jwtOptionsFactory(tokenService) {
  return {
    tokenGetter: () => {
      return localStorage.get('access_token');
  }
}
}
*/

/*
export function authHttpServiceFactory(http: Http) {
  return new AuthHttp(new AuthConfig({
    headerPrefix: 'Bearer',
    tokenName: TOKEN_NAME,
    globalHeaders: [{'Content-Type': 'application/json'}],
    noJwtError: false,
    noTokenScheme: true,
    tokenGetter: (() => localStorage.getItem(TOKEN_NAME))
  }), http);
}
*/



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


export function tokenGetterFunction() {
  return sessionStorage.getItem('auth-token');
}



@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    SearchEngineComponent
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    CKEditorModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    DxTreeListModule,
    DxButtonModule,
    DxDataGridModule,
    DxSparklineModule,
    DxTemplateModule,
    DxListModule,
    DxToolbarModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxContextMenuModule,
    DxFileUploaderModule,
    DxSwitchModule,
    DxTreeViewModule,
    DxDropDownBoxModule,
    DxPopupModule,
    AngularSplitModule,
    NgIdleKeepaliveModule.forRoot(),
 /*   ToastrModule.forRoot(),*/
   /*   NgbModule.forRoot(), */
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

    JwtModule.forRoot({
      config: {
        tokenGetter: (tokenGetterFunction),
        allowedDomains: ['app.qse-veille.fr:8080', 'qseveille.intraco-consulting.com', 'qseveille.intraco-consulting.com:8080', 'preprod.qse-veille.fr:8080', 'preprod.qse-veille.fr', 'localhost:8080' ]
      }
    })

  ],

  providers: [
    {provide: String, useValue: "dummy"},
    AppConfig,
       { provide: APP_INITIALIZER,
         useFactory: initializeApp,
         deps: [AppConfig], multi: true },

    /*
    {
      // https://stomp-js.github.io/guide/ng2-stompjs/2018/11/04/ng2-stomp-with-angular7.html
      provide: InjectableRxStompConfig,
      useValue: myRxStompConfig
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig]
    },
*/
    TaxonomieService,
    DatePipe,

    //Toastr and auth providers
   // { provide: ToastOptions, useClass: CustomOption },
    AuthService,
    UserService,
    SiteChangeService,
    AdministrateurService,
    AuthGuard,
    AdminAuthGuard,
    AdminChatService,
    MessageBusService,
    AdministrateurNotificationsService,
    TranslateService, 
    {provide: RouteReuseStrategy,
     useClass: CacheRouteReuseStrategy},
      Idle, Keepalive
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }




// https://stomp-js.github.io/guide/ng2-stompjs/2018/11/04/ng2-stomp-with-angular7.html
