import { CommonModel } from "app/shared/common/common.model"

export class ToEvaluateModel extends CommonModel {

    dateConstat: Date = new Date()


    constructor(){
       super() 
    }

    init(_currentEE: any){
        this.dateConstat = _currentEE.dateConstat 
        this.notes = _currentEE.notes 
        super.initCommon(_currentEE)
        return this
    }

    reset(){
        return this
    }
}