import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { EvaluationExigenceService } from 'app/services/evaluation-exigence.service';
import { DxDropDownBoxComponent } from 'devextreme-angular';

@Component({
  selector: 'app-status-selector',
  templateUrl: './status-selector.component.html',
  styleUrls: ['./status-selector.component.scss']
})
export class StatusSelectorComponent implements OnInit /* OnChanges */ {

  @Input() value: number[] = [0]
  @Output() change:EventEmitter<number[]> = new EventEmitter<number[]>();

  statutFilterDataSource: any;

  constructor(private eeService: EvaluationExigenceService) {
    this.statutFilterDataSource = this.eeService.getStatutFilterDataSource();
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
  }

  /*
  ngOnChanges(changes: SimpleChanges) {
    console.log("StatusSelectorComponent:ONCHANGE:", changes);
    if(changes.currentValue) {
    let v:Array<number> = (changes.currentValue as unknown) as Array<number> ;
    if(v.length==0)
        this.value = [0];
    }
  }
  */

  onStatutChanged(e) {
    console.log("### onStatutChanged:------:", e);

    if(e.value.length==0){
        this.value = [0];
        this.change.emit( this.value);
    }
   else {
    let result = []
    var toutes:boolean = false;
    var evaluees:boolean = false;
    e.value.map( x => {
      if(x.value != undefined){
        if(x.value==0) toutes = true;
        if(x.value==2) evaluees = true;
        result.push(x.value)
      }
      else
        result.push(x)
    });
 
    if(toutes) result = [0]
   // else if(evaluees)

    this.change.emit(result);
  }
}
}
