import { Component, OnInit } from '@angular/core';
import notify_ from 'devextreme/ui/notify';

@Component({
  selector: 'app-util',
  templateUrl: './util.component.html',
  styleUrls: ['./util.component.scss']
})
export class UtilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  static remove(arr, value) {
    return arr.filter(item => {
      return item !== value
    })
  }

}

export function notify(text: string, level?: string, timeout?: number) {
  var posY = 0;
  var allObjects = $(".dx-toast-content");

  if (allObjects.length > 0) {
    posY = 100 + allObjects.length * 60
  } else {
    posY = 100
  }

  let posX = Math.round(0.75 * (window.innerWidth / 2))

  notify_({
    message: text,
    position: {my: 'top', at: 'top', offset: `${posX} ${posY}`, of: window, collision: 'flip' },
    width: "30%",
    animation: {show: { type: 'slide', duration: 400, from: { left: $(window).width()}, to: {top: `${posY}`}},
                hide: { type: 'slide', duration: 400, from: { top: `${posY}`}, to: {left: $(window).width()}}},
    closeOnClick: true
  }, level, timeout)
}

export function notifyUpdated() {
  var posY = 0;
  var allObjects = $(".dx-toast-content");

  if (allObjects.length > 0) {
    posY = 100 + allObjects.length * 62
  } else {
    posY = 100
  }

  let posX = Math.round(0.99 * (window.innerWidth / 2))

  notify_({
    message: "",
    position: {my: 'top', at: 'top', offset: `${posX} ${posY}`, of: window, collision: 'flip' },
    width: "10%", 
    height: "10%",
    animation: {show: { type: 'slide', duration: 400, from: { left: $(window).width()}, to: {top: `${posY}`}},
                hide: { type: 'slide', duration: 400, from: { top: `${posY}`}, to: {left: $(window).width()}}},
    closeOnClick: true,
    contentTemplate: `<i class="fa">&#xf0c7;</i>`
  }, "custom", 900)
}
