<div class="dx-fieldset">
  <div class="dx-field">
      <div *ngIf= "checkSelection()" class="dx-field-label"> <p class="card-text">{{nomClient}}</p></div>
      <div class="dx-field-value">
        <dx-drop-down-box #dropBox
              [dataSource]="clientDataSource"
              [(value)]="selectedClient"
              valueExpr="id"
              [displayExpr]="gridBox_displayExpr"
              [placeholder]="'client-selecteur-nomClient*choisir-un-client'|translate"
              [showClearButton]="true"
              (onValueChanged)="dropdown_syncGridSelection($event)"
              >

              <div *dxTemplate="let data of 'content'">
                <dx-data-grid #clientGrid  id="clientGrid"  height="400px"
                      [dataSource]="clientDataSource"
                      [(selectedRowKeys)]="gridSelectedRowKeys"
                      (onSelectionChanged)="dropdown_gridItemSelectionChanged($event)"
                      (onContentReady)="$event.component.selectRowsByIndexes(treeBoxValue)"
                      [showCheckBoxesMode]
                      [wordWrapEnabled]="true"
                      [columnAutoWidth]="true"
                      [selection]="selection"
                      cacheEnabled="true"
                      [hoverStateEnabled]="true"
                      >

                      <dxo-scrolling mode="standard">   </dxo-scrolling>

                      <dxo-remote-operations
                          [filtering]="true"
                          [paging]="true"
                          [sorting]="true"
                          [grouping]="false"></dxo-remote-operations>
                        <dxo-paging [pageSize]="6" [enabled]="true"></dxo-paging>
                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[ 10, 20, 30]"></dxo-pager>

                      <dxo-search-panel [visible]="true"></dxo-search-panel>
                      <dxo-selection mode="single"  ></dxo-selection>

                    <dxi-column cssClass="align-cell-vertical" width="64" cssClass="logoclient" dataField="logo" cellTemplate="cellTemplate"
                        editCellTemplate="editCellTemplate" dataType="string" [allowSorting]="false" [visible]="true" [formItem]="{visible: true}"
                        [caption]="'clients-field-label*logo'|translate" [allowSearch]="false">
                    </dxi-column>

                      <dxi-column dataField="nomClientEtendu" [caption]="'clients-field-label*nom'|translate" [allowSearch]="true" [allowSorting]="true" ></dxi-column>

                      <div *dxTemplate="let cell of 'cellTemplate'">
                        <img class="logoclient" *ngIf="cell.data.logo != null" [src]="cell.data.logo" [width]="32" [height]="32" />
                      </div>

                     </dx-data-grid>
              </div>
          </dx-drop-down-box>
      </div>
  </div>
</div>
