<dx-form #cForm [(formData)]="model" (onDataFieldChange)="onDataFieldChange($event, cForm)"
         [alignItemLabels]="false" [alignItemLabelsInAllGroups]="false" labelLocation="left" [showColonAfterLabel]="false" [colCount]="'auto'" 
         [minColWidth]="768" [elementAttr]="formAttr">

        <!-- Conformity --> 
        <dxi-item itemType="group"  [colSpan]="2" [colCount]="2" [visible]="!isGapLeft() && model.lastUserStatut == undefined">
            <dxi-item dataField="dateConstat" dataType="date" editorType="dxDateBox" [editorOptions]="dateConstatOptions()">
                <dxo-label [text]="'exigence/form-label*Statué le'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="dateValidite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions">
                <dxo-label [text]="'exigence/form-label*Statut valide jusqu'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="notes" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                    [colSpan]="2">
                <dxo-label [text]="'exigence/form-label*Remarque'|translate"></dxo-label>
            </dxi-item>
        </dxi-item>

        <dxi-item itemType="group"  [colSpan]="2" [colCount]="2" [visible]="!isGapLeft() && model.lastUserStatut != undefined">
            <dxi-item dataField="dateConstat" dataType="date" editorType="dxDateBox" [editorOptions]="dateConstatOptions()">
                <dxo-label [text]="'exigence/form-label*Statué le'|translate"></dxo-label>
            </dxi-item>
 
            <dxi-item dataField="lastUserStatut" dataType="string" editorType="dxTextBox" [editorOptions]="readonlyText">
                <dxo-label [text]="'exigence/form-label*Par'|translate"></dxo-label>
            </dxi-item>

            <dxi-item itemType="empty"></dxi-item>

            <dxi-item dataField="dateValidite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions">
                <dxo-label [text]="'exigence/form-label*Statut valide jusqu'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="notes" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                    [colSpan]="2">
                <dxo-label [text]="'exigence/form-label*Remarque'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1">
                <div *dxTemplate>
                    <dx-check-box [(value)]="model.modeleAction"></dx-check-box>
                </div>
                <dxo-label [text]="'exigence/form-caption*Modèle daction'|translate"></dxo-label>
            </dxi-item>
        </dxi-item>

        <!-- Lifting gap -->
        <dxi-item itemType="group" [colSpan]="2" [colCount]="2" [caption]="'exigence/form-caption*Levée décart'|translate" [visible]="isGapLeft() && !hasLeveeEcartUser()">

            <dxi-item dataField="dateConstat" dataType="date" editorType="dxDateBox" [editorOptions]="dateConstatOptions()">
                <dxo-label [text]="'exigence/form-label*Premier constat statué le'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="dateResolution" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions">
                <dxo-label [text]="'exigence/form-label*Ecart levé le'|translate"></dxo-label>
            </dxi-item>

            <dxi-item itemType="empty"></dxi-item>

            <dxi-item dataField="dateValidite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions">
                <dxo-label [text]="'exigence/form-label*Statut valide jusqu'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="actions" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                    [colSpan]="2">
                <dxo-label [text]="'exigence/form-label*Action pour lever lécart'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="notes" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                    [colSpan]="2">
                <dxo-label [text]="'exigence/form-label*Remarque'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1">
                <div *dxTemplate>
                    <dx-check-box [(value)]="model.modeleAction"></dx-check-box>
                </div>
                <dxo-label [text]="'exigence/form-caption*Modèle daction'|translate"></dxo-label>
            </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colSpan]="2" [colCount]="2" [caption]="'exigence/form-caption*Levée décart'|translate" [visible]="isGapLeft() && hasLeveeEcartUser()">

            <dxi-item dataField="dateConstat" dataType="date" editorType="dxDateBox" [editorOptions]="dateConstatOptions()">
                <dxo-label [text]="'exigence/form-label*Premier constat statué le'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="lastUserNCorAV" dataType="string" editorType="dxTextBox" [editorOptions]="readonlyText" [visible]="hasLeveeEcartActions()">
                <dxo-label [text]="'exigence/form-label*Par'|translate"></dxo-label>
            </dxi-item>

            <dxi-item itemType="empty" [visible]="!hasLeveeEcartActions()"></dxi-item>

            <dxi-item dataField="nomResponsable" dataType="string" editorType="dxTextBox" [editorOptions]="readonlyText" [visible]="responsableDefined()">
                <!-- <dxo-label [text]="'exigence/form-label*Par'|translate"></dxo-label> -->
                <dxo-label [text]="'exigence/form-label*Action conduite par'|translate"></dxo-label>
            </dxi-item>

            <dxi-item itemType="empty" [visible]="responsableDefined()"></dxi-item>

            <dxi-item dataField="dateResolution" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions">
                <dxo-label [text]="'exigence/form-label*Ecart levé le'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="lastUserLeveeEcart" dataType="string" editorType="dxTextBox" [editorOptions]="readonlyText">
                <dxo-label [text]="'exigence/form-label*Par'|translate"></dxo-label>
            </dxi-item>

            <dxi-item itemType="empty"></dxi-item>

            <dxi-item dataField="dateValidite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions">
                <dxo-label [text]="'exigence/form-label*Statut valide jusqu'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="actions" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                    [colSpan]="2">
                <dxo-label [text]="'exigence/form-label*Action pour lever lécart'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="notes" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                    [colSpan]="2">
                <dxo-label [text]="'exigence/form-label*Remarque'|translate"></dxo-label>
            </dxi-item>
            <dxi-item [colSpan]="1">
                <div *dxTemplate>
                    <dx-check-box [(value)]="model.modeleAction"></dx-check-box>
                </div>
                <dxo-label [text]="'exigence/form-caption*Modèle daction'|translate"></dxo-label>
            </dxi-item>
        </dxi-item>

        <!-- Lifting gap evaluation - ISO -->
        <dxi-item itemType="group" [colSpan]="2" [colCount]="2" [caption]="'exigence/form-caption*ISO'|translate" [visible]="isGapLeft() && iso">

            <dxi-item dataField="moyens" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                    [colSpan]="2">
                <dxo-label [text]="'exigence/form-label*Moyen'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="montant" editorType="dxNumberBox" [editorOptions]="amountNumberOptions">
                <dxo-label [text]="'exigence/form-label*Coût de laction'|translate"></dxo-label>
            </dxi-item>

            <dxi-item itemType="empty" [colSpan]="1"></dxi-item>

            <dxi-item [colSpan]="1" dataField="efficacite" editorType="dxNumberBox" [editorOptions]="percentNumberOptions">
                <dxo-label [text]="'exigence/form-label*Efficacité de la levée décart'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="service">
                <div *dxTemplate="let data of 'serviceTemplate'">
                    <app-service-selecteur [selectedSite]="currentSite"  
                                        [selectedService]="data.component.option('formData')[data.dataField]" 
                                        (serviceUpdated)="onServiceUpdated($event)">
                    </app-service-selecteur>
                </div>
                <dxo-label [text]="'exigence/form-label*Service'|translate"></dxo-label>
            </dxi-item>

        </dxi-item>

        <!-- Revision & Opportunity actions --> 
        <dxi-item itemType="group" [colSpan]="2" [colCount]="8"> 
            <dxi-item [colSpan]="2">
                <div *dxTemplate class="eeFormInActions">
                    <dx-button [text]="'exigence/form-label*Réviser'|translate" type="success" [hint]="'exigence/form-hint*Vous considérez lexigence toujours valide'|translate"
                                (onClick)="manageRevision($event)" [visible]="canRevise()" ></dx-button>

                    <dx-button [text]="'exigence/form-label*Créer une opportunité'|translate" type="success" [hint]="'exigence/form-hint*Vous souhaitez améliorer lévaluation de la conformité'|translate"
                                (onClick)="setOpportunityFlag($event)" [visible]="canDoOpportunity()" ></dx-button>

                    <dx-button [text]="'exigence/form-label*Ne pas créer dopportunité'|translate" type="success" 
                                (onClick)="unSetOpprtunityFlag($event)" [visible]="canNotDoOpportunity()" ></dx-button>
                </div>
            </dxi-item> 
        </dxi-item>

        <!-- Revision -->
        <dxi-item itemType="group" [colSpan]="2" [colCount]="2" [visible]="isRevised()" [caption]="'exigence/form-label*Réviser'|translate">

            <dxi-item dataField="dateRevision" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions">
                <dxo-label [text]="'exigence/form-label*Conformité révisée le'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="lastUserRevision" dataType="string" editorType="dxTextBox" [editorOptions]="readonlyText" [visible]="model.lastUserRevision != undefined">
                <dxo-label [text]="'exigence/form-label*Par'|translate"></dxo-label>
            </dxi-item>

        </dxi-item>

        <!-- Opportunity -->
        <dxi-item itemType="group" [colSpan]="2" [colCount]="2" [visible]="opportunityEnabled() && !hasOpportunityAction()"
            [caption]="'exigence/form-caption*Opportunité'|translate">

            <dxi-item dataField="dateOuvertureOpportunite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions" 
                [colSpan]="1">
                <dxo-label [text]="'exigence/form-label*Détection le'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="dateLimiteOpportunite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions" 
                [colSpan]="1">
                <dxo-label [text]="'exigence/form-label*Opportunité à réaliser avant le'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="opportunite" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                [colSpan]="2">
                <dxo-label [text]="'exigence/form-label*Action'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="prioriteOpportunite" editorType="dxSelectBox" [editorOptions]="priorityOptions">
                <dxo-label [text]="'exigence/form-label*Priorité'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="responsableOpportunite">
                <div *dxTemplate="let data of 'respTemplate'"> 
                    <app-responsable-selecteur [selectedSite]="currentSite" 
                                        [selectedResponsable]="data.component.option('formData')[data.dataField]"
                                        (responsableUpdated)="onResponsableOpportuniteUpdated($event)">
                    </app-responsable-selecteur> 
                  </div>
                  <dxo-label [text]="'exigence/form-label*Responsable de lopportunité'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="serviceOpportunite">
                <div *dxTemplate="let data of 'serviceTemplate'">
                    <app-service-selecteur [selectedSite]="currentSite"  
                                        [selectedService]="data.component.option('formData')[data.dataField]" 
                                        (serviceUpdated)="onServiceOpportunityUpdated($event)">
                    </app-service-selecteur>
                </div>
                <dxo-label [text]="'exigence/form-label*Service visé par lopportunité'|translate"></dxo-label>
            </dxi-item>

        </dxi-item>

        <dxi-item itemType="group" [colSpan]="2" [colCount]="2" [visible]="opportunityEnabled() && hasOpportunityAction()"
            [caption]="'exigence/form-caption*Opportunité'|translate">

            <dxi-item dataField="dateOuvertureOpportunite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions" 
                [colSpan]="1">
                <dxo-label [text]="'exigence/form-label*Détection le'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="lastUserOpportunity" dataType="string" editorType="dxTextBox" [editorOptions]="readonlyText">
                <dxo-label [text]="'exigence/form-label*Par'|translate"></dxo-label>
            </dxi-item>

            <dxi-item itemType="empty"></dxi-item>

            <dxi-item dataField="dateLimiteOpportunite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions" 
                [colSpan]="1">
                <dxo-label [text]="'exigence/form-label*Opportunité à réaliser avant le'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="opportunite" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                [colSpan]="2">
                <dxo-label [text]="'exigence/form-label*Action'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="prioriteOpportunite" editorType="dxSelectBox" [editorOptions]="priorityOptions">
                <dxo-label [text]="'exigence/form-label*Priorité'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="responsableOpportunite">
                <div *dxTemplate="let data of 'respTemplate'"> 
                    <app-responsable-selecteur [selectedSite]="currentSite" 
                                        [selectedResponsable]="data.component.option('formData')[data.dataField]"
                                        (responsableUpdated)="onResponsableOpportuniteUpdated($event)">
                    </app-responsable-selecteur> 
                  </div>
                  <dxo-label [text]="'exigence/form-label*Responsable de lopportunité'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="serviceOpportunite">
                <div *dxTemplate="let data of 'serviceTemplate'">
                    <app-service-selecteur [selectedSite]="currentSite"  
                                        [selectedService]="data.component.option('formData')[data.dataField]" 
                                        (serviceUpdated)="onServiceOpportunityUpdated($event)">
                    </app-service-selecteur>
                </div>
                <dxo-label [text]="'exigence/form-label*Service visé par lopportunité'|translate"></dxo-label>
            </dxi-item>

        </dxi-item>


        <!-- Opportunity realization -->
        <dxi-item itemType="group" [colSpan]="2" [colCount]="2" [visible]="opportunityEnabled()">

            <div *dxTemplate class="eeFormInActions">
                <dx-button [text]="'exigence/form-label*Opportunité réalisée'|translate" type="success" [hint]="'exigence/form-hint*Vous indiquez que lopportunité a été réalisée'|translate"
                            (onClick)="onOpportunityRealized($event)" [visible]="canRealizeOpportunity()"  ></dx-button>
            </div>

        </dxi-item>

        <!-- Opportunity evaluation -->
        <dxi-item itemType="group" [colSpan]="2" [colCount]="2" [visible]="isOpportunityRealized() && !hasOppRealizedAction()" [caption]="'exigence/form-caption*Evaluation de lopportunité'|translate">

            <dxi-item dataField="dateRealisationOpportunite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions" 
                [colSpan]="1" [visible]="isOpportunityRealized()">
                <dxo-label [text]="'exigence/form-label*Opportunité réalisée le'|translate"></dxo-label>
            </dxi-item>

        </dxi-item>

        <dxi-item itemType="group" [colSpan]="2" [colCount]="2" [visible]="isOpportunityRealized() && hasOppRealizedAction()" [caption]="'exigence/form-caption*Evaluation de lopportunité'|translate">

            <dxi-item dataField="dateRealisationOpportunite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions" 
                [colSpan]="1" [visible]="isOpportunityRealized()">
                <dxo-label [text]="'exigence/form-label*Opportunité réalisée le'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="lastUserOpportunityRealized" dataType="string" editorType="dxTextBox" [editorOptions]="readonlyText">
                <dxo-label [text]="'exigence/form-label*Par'|translate"></dxo-label>
            </dxi-item>

        </dxi-item>

</dx-form>
