import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-doc-uploader',
  templateUrl: './doc-uploader.component.html',
  styleUrls: ['./doc-uploader.component.scss']
})
export class DocUploaderComponent implements OnInit {

  @Input() container: any

  constructor() { }

  ngOnInit(): void {
  }

  onFileUploaderValueChanged(event: any, cell: any) {
    if (event.value && event.value[0]) {
      let file = event.value[0];
      let filename = file.name.split('.');
      if (filename.length != 2) {
        console.error("Nom de fichier invalide.");
        alert("Nom de fichier invalide (fichier.ext requis)");
        return;
      }
      let name = filename[0];
      let ext = filename[1];

      cell.fichier = filename;
      cell.row.data.extension = ext;

      let reader = new FileReader();
      reader.onload = () => {
        cell.setValue(reader.result);
      }

      reader.readAsDataURL(file);
    }
  }


}
