import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxDropDownBoxComponent } from "devextreme-angular";
import { ClientService } from 'app/services/client.service';
import { TranslateService } from '@ngx-translate/core';
import { QSE_LAST_LANGUE } from 'app/services/user.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-clients-selecteur',
  templateUrl: './clients-selecteur.component.html',
  styleUrls: ['./clients-selecteur.component.scss']
})
export class ClientsSelecteurComponent implements OnInit {

  @ViewChild('clientGrid') clientGrid: DxDataGridComponent;
  @ViewChild('dropBox') dropBox: DxDropDownBoxComponent;
  @Output() clientUpdated = new EventEmitter();
  @Input('selectedClients') selectedClients: any = []

  // datasources
  clientDataSource: any;
  nomClient: string = "Choisir un client";

  selectedKeys_ = []
  selected_ = []


  constructor(private clientService: ClientService, public translate: TranslateService) {

    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
    this.translate.get(
      [
        'client-selecteur-nomClient*choisir-un-client'

      ]
    )
      .subscribe(val => {


        this.nomClient = val['client-selecteur-nomClient*choisir-un-client'];

      });


    this.clientDataSource = clientService.getRestDataSource();

  }

  ngOnInit() {
  }


  reset() {
  }

  ngAfterViewInit() {
  }

  dropdown_syncGridSelection(event) {

    if (!this.clientGrid) return;

    if (event.value === null) {
      this.nomClient = "";
      this.clientGrid.instance.clearSelection();
    }
  }

  dropdown_gridItemSelectionChanged(event) {
    let selected = []
    let selectedKeys = []
    event.component.getSelectedRowsData().done(data => {
      selected = data
      this.selected_ = data
      for (let client of data) {
        selectedKeys.push(client.id)
      }
      this.selectedKeys_ = selectedKeys
      this.clientUpdated.emit(selected);
    })
  }


  gridBox_displayExpr(item) {
    return item && item.nom;
  }


  clientGrid_onContentReady(e: any) {
    let tabs = document.getElementsByClassName('dx-header-row')
    for (let idx = 0; idx < tabs.length; idx++) {
      let tab = tabs[idx]
      let checkbox = tab.firstElementChild.firstElementChild
      checkbox.addEventListener('click', e => {
        let checked_ = checkbox.firstElementChild
        let checked = checked_.getAttribute('value')
        if (checked == 'true') {
          console.log(`=== checked`)
          let selectedKeys = []
          let selected = []
          let clients
          this.clientGrid.instance.getDataSource().store().load().done((res: any) => {
            clients = res.data
            for (let client of clients) {
              selectedKeys.push(client.id)
              selected.push(client)
            }
            this.clientGrid.selectedRowKeys = selectedKeys
            this.selectedKeys_ = selectedKeys
            this.selected_ = selected
            this.clientUpdated.emit(selected)
            // Artefact
            // checkbox.setAttribute('class', 'dx-widget dx-checkbox dx-checkbox-checked dx-select-checkbox dx-datagrid-checkbox-size')
            // checkbox.setAttribute('aria-checked', 'true')
            // checked_.setAttribute('value', 'true')
          })

        } else {
          console.log(`=== not checked`)
          this.clientGrid.selectedRowKeys = []
          this.selected_ = []
          // Artefact
          // checkbox.setAttribute('class', 'dx-widget dx-checkbox dx-select-checkbox dx-datagrid-checkbox-size')
          // checkbox.setAttribute('aria-checked', 'false')
          // checked_.setAttribute('value', 'false')
        }
      })
    }
  }

}

