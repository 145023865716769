<div class="dx-fieldset">
  <div class="dx-field">
      <div class="dx-field-label"> <p class="card-text">{{nomSite}}</p></div>
      <div class="dx-field-value">
        <dx-drop-down-box #dropBox
              [dataSource]="siteDataSource"
              [(value)]="selectedSite"
              valueExpr="id"
              [displayExpr]="gridBox_displayExpr"
              [placeholder]="'site-selecteur-placeholder*nomSite'|translate"
              [showClearButton]="true"
              (onValueChanged)="dropdown_syncGridSelection($event)" 
         
              >

              <div *dxTemplate="let data of 'content'">
                <dx-data-grid #siteGrid  id="siteGrid"  height="400px"
                      [dataSource]="siteDataSource"
                    
                     
                      [wordWrapEnabled]="true"
                      [columnAutoWidth]="true"
                      cacheEnabled="true"     
                      [hoverStateEnabled]="true"

                      [(selectedRowKeys)]="gridSelectedRowKeys"
                      (onSelectionChanged)="dropdown_gridItemSelectionChanged($event)" 
                      (onContentReady)="$event.component.selectRowsByIndexes(treeBoxValue)" >
                  
                      <dxo-scrolling mode="standard">   </dxo-scrolling>
                  
                      <dxo-remote-operations 
                          [filtering]="true" 
                          [paging]="true" 
                          [sorting]="true" 
                          [grouping]="false"></dxo-remote-operations>
                        <dxo-paging [pageSize]="9" [enabled]="true"></dxo-paging>
                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[  10, 15, 20]"></dxo-pager>

                      <dxo-search-panel [visible]="true"></dxo-search-panel>
                      <dxo-selection mode="single"  ></dxo-selection>

                    <dxi-column cssClass="align-cell-vertical" width="64" cssClass="logoclient" dataField="logo" cellTemplate="cellTemplate"
                        editCellTemplate="editCellTemplate" dataType="string" [allowSorting]="false" [visible]="true" [formItem]="{visible: true}"
                        caption="Logo" [allowSearch]="false">
                    </dxi-column>

                      <dxi-column dataField="nom" caption="Nom" [allowSorting]="true" ></dxi-column>
                     
                      <div *dxTemplate="let cell of 'cellTemplate'">
                        <img class="logoclient" *ngIf="cell.data.logo != null" [src]="cell.data.logo" [width]="32" [height]="32" />
                      </div>
                      
                     </dx-data-grid>
              </div>
          </dx-drop-down-box>
      </div>
  </div>
</div>