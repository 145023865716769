<div class="chart-cell">

    <dx-pie-chart #chart id="pie" title="" [palette]="pieColorPalette" [dataSource]="measures">
      <dxi-series argumentField="measure" valueField="count">
        <dxo-label [visible]="true">
            <dxo-connector [visible]="true" [width]="1"></dxo-connector>
          </dxo-label>
      </dxi-series>
      <dxo-legend [visible]="false"></dxo-legend>
      <dxo-size [width]="64" [height]="64"></dxo-size>

    </dx-pie-chart>

  </div>
