import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app.config';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { getHttpHeaders, jsToSQLFilter, replaceInFilter } from './commons';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class GroupeService extends CustomStore {

  private dataSource: any;
  public fastMode : any = [0];


  // Retourne un objet groupe
  getGroupeForClient(client_id) {
    return this.http.get<any>(AppConfig.settings.api +"/api/groupe/client/" + encodeURIComponent(client_id), { headers: getHttpHeaders()}).toPromise();
  }

  // Retourne un objet groupe
  getGroupeForSite(site_id) {
    return this.http.get<any>(AppConfig.settings.api +"/api/groupe/site/" + encodeURIComponent(site_id), { headers: getHttpHeaders()}).toPromise();
  }

  colporteDatesModel(client_id: any, mode: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/groupe/colportedates/" + encodeURIComponent(client_id) + "?mode=" + mode, { headers: getHttpHeaders() }).toPromise();
  }
  colporteDatesModelAvecDivisions(groupe_id: any, mode: any, divisions:string, utilisateurs, utilisateursMode, dateDebut, dateFin, dateMode) {
    return this.http.get<any>(AppConfig.settings.api + "/api/groupe/colportedates/" + encodeURIComponent(groupe_id) 
    + "?mode=" + mode
    + "&divisions=" + encodeURIComponent(divisions) 
    +"&utilisateurs=" + encodeURIComponent(utilisateurs)
    +"&utilisateursMode=" + encodeURIComponent(utilisateursMode)
    +"&dateDebut=" + encodeURIComponent(dateDebut)
    +"&dateFin=" + encodeURIComponent(dateFin)
    +"&dateMode=" + encodeURIComponent(dateMode)
    
    , { headers: getHttpHeaders() }).toPromise();
  }


  generateGraphFromClient(client_id: number, filename:string ) {

     const options = {
       headers: new HttpHeaders({
         responseType:  'blob'
         })
       };
 
     var headers = new HttpHeaders()
       .set('Access-Control-Allow-Origin', '*')
       .set('Accept', 'application/octet-stream')
       .set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE')
       .set('Access-Control-Allow-Headers', 'X-Requested-With, responseType,Origin, Content-Type,Accept, X-Auth-Token ,App-Auth,X-XSRF-TOKEN,Authorization')
       .set('Access-Control-Allow-Credentials', "true");
 
     const url = AppConfig.settings.api + "/api/groupe/client/makegraph/" + encodeURIComponent(client_id) + "?mode=0" 
     this.http.get(url, { headers: getHttpHeaders(), responseType: 'blob'}).toPromise().then(response => {
       saveAs(response, filename);
    }).catch(error => {
       console.log("ERROR=", error);
     });
   }


   generateGraphFromGroupe(groupe_id: number, filename:string ) {

    const options = {
      headers: new HttpHeaders({
        responseType:  'blob'
        })
      };

    var headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', 'application/octet-stream')
      .set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE')
      .set('Access-Control-Allow-Headers', 'X-Requested-With, responseType,Origin, Content-Type,Accept, X-Auth-Token ,App-Auth,X-XSRF-TOKEN,Authorization')
      .set('Access-Control-Allow-Credentials', "true");

    const url = AppConfig.settings.api + "/api/groupe/makegraph/" + encodeURIComponent(groupe_id) + "?mode=0" 
    this.http.get(url, { headers: getHttpHeaders(), responseType: 'blob'}).toPromise().then(response => {
      saveAs(response, filename);
   }).catch(error => {
      console.log("ERROR=", error);
    });
  }




  constructor(private http: HttpClient)  {
    super();
    let SERVICE_URL = AppConfig.settings.api + "/api/groupe/";
    var fastMode = this.fastMode;

    this.dataSource = new CustomStore(
      {
        key: "id",

        load: function (loadOptions: any) :any {
          var URL = SERVICE_URL;
          let filter = "";

          if (loadOptions.filter) {
            replaceInFilter(loadOptions.filter, "nom", "n.nom");
            replaceInFilter(loadOptions.filter, "email", "n.email");
            replaceInFilter(loadOptions.filter, "telephone", "n.telephone");
            replaceInFilter(loadOptions.filter, "partenaire", "n.partenaire");

            filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));


            // On passe en fast mode seulement si une chaine a aété saisie dans le champ de recherche
            if(fastMode[0] && loadOptions.filter[0] !="archive") {
               URL = SERVICE_URL + "fast/";
              if(loadOptions.filter.length==3) {
              //  var F = [["archive", "=", loadOptions.filter[2][2] ], "and", ["n.nom", "contains", loadOptions.filter[0][0][2]] ];
              var F = undefined;
              if(loadOptions.filter[2][0]=="archive")
                 F = [["archive", "=", loadOptions.filter[2][2] ], "and", ["n.nom", "contains",loadOptions.filter[0][0][2] ], "AND", ["virtuel", "=", 0] ];
                 else
                 F = [["archive", "=", "0" ], "and", ["n.nom", "contains", loadOptions.filter.filterValue ], "AND", ["virtuel", "=", 0]];

                filter = "?filter=" + encodeURIComponent(jsToSQLFilter(F));
              }
            }
          }
          else
            filter = "?filter=" + encodeURIComponent(jsToSQLFilter([["archive", "=", 0], "AND", ["virtuel", "=", 0]]));


            

          if (loadOptions.skip) {
            filter = filter + "&skip=" + loadOptions.skip;
          }
          else {
            filter = filter + "&skip=0";
          }

          if (loadOptions.take) {
            filter = filter + "&take=" + loadOptions.take;
          }

          if (loadOptions.sort) {
            var usort = "";
            for (var i = 0; i < loadOptions.sort.length; i++) {
              var field = "";
              if (loadOptions.sort[i].selector == "nom")
                field = "n.nom";
              else
                if (loadOptions.sort[i].selector == "activites")
                  field = "n.activites";
                  else
                   field = loadOptions.sort[i].selector;

              usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
              if (i + 1 < loadOptions.sort.length)
                usort = usort + ",";
            }
            filter = filter + "&sort=" + encodeURIComponent(usort);
          }


          console.log("APPEL: ", URL + filter);
          return http.get<any>(URL + filter, { headers: getHttpHeaders() }).toPromise()
            .then(response => {
          if(AppConfig.settings.trace)
            console.log("---------------> Groupe SERVER RESPONSE: ", response);
              return response;
            });
        },

        // totalCount: function(loadOptions:any) { return 0;},

        byKey: function (key) {
           return http.get(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        },


        insert: function (values:any) {
      
          return http.post<any>(SERVICE_URL, values, { headers: getHttpHeaders() }).toPromise().then(response => {
            return {
              values: response.values,
              key: response.key
            }
          }).catch(function (err) {
            notify("ERREUR: " + err);
          });
        },


        update: function (key, values) {
              return http.put<any>(SERVICE_URL + encodeURIComponent(key), values, { headers: getHttpHeaders() }).toPromise().then(response => {
            notify('Mise à jour effectuée');
            return {
              result: response.result,
              key: response.key
            }
          });
        },



        remove: function (key) {
          return http.delete<any>(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        }
      }
      );

    }


    getRestDataSource() {
      return this.dataSource;
    }

    
}
