import { Component, Injectable, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonComponent } from 'app/shared/common/common.component';
import notify from 'devextreme/ui/notify';
import { EvaluationExigenceFormService } from '../form.service';
import { NotCompliantModel } from './not-compliant.model';

@Component({
  selector: 'app-ee-not-compliant',
  templateUrl: './not-compliant.component.html',
  styleUrls: ['./not-compliant.component.scss']
})

@Injectable()
export class NotCompliantComponent extends CommonComponent implements OnInit {

  @Input() @Output('model') model: NotCompliantModel 
  // @Input() currentSite: any
  @Input('statut') statut: number
  @Input('initialStatut') initialStatut: number

  constructor(public eeFormService: EvaluationExigenceFormService, public translate: TranslateService) {
    super(translate)
   }

  hierarchyOptions: any 

  ngOnInit(): void {
    super.ngOnInit()
    this.hierarchyOptions = {
      disabled: true,
      value : this.model.gravite * this.model.frequence * this.model.priorite
    }
  }

  onDataFieldChange(e: any, form: any){
    form[e.field] = e.value
  }

  onResponsibleUpdated(e: any){
    // e is thrown undefined when we clear the field
    if(e !== undefined){
      this.model.responsable = e.id
    } else {
      this.model.responsable = 0
    }
    
  }

  checkResponsible() {
    if(this.model.notifyResponsible && !(this.model.responsable !== undefined && this.model.responsable > 0 )){
      notify("Il faut tout d'abord définir un responsable", 'error');
    }
  }

  onServiceUpdated(e: any){
    // e is thrown undefined when we clear the field
    if(e !== undefined){
      this.model.service = e.id
    } else {
      this.model.service = 0
    }
  }

}
