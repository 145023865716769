import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-collapsible-panel',
  templateUrl: './collapsible-panel.component.html',
  styleUrls: ['./collapsible-panel.component.scss']
})
export class CollapsiblePanelComponent implements OnInit, OnDestroy {

  // Fields
  show: boolean = false
  @Input() title: string
  @Input() default: boolean = false
  @Input() class: string
  @Input() extraHtml: string
  @Input() disabled: boolean = false

  ngOnInit(): void {
    console.log(`=== init panel: ${this.title} | ${sessionStorage.getItem(`state-${this.title}`)}`)
    this.show = this.default
    if(sessionStorage.getItem(`state-${this.title}`)){
      this.show = sessionStorage.getItem(`state-${this.title}`) === 'true'
    }
  }

  showPanelOrNot(e: any){
    this.show = !this.show
    sessionStorage.setItem(`state-${this.title}`, String(this.show))
    return !this.show
  }

  showOrNotClass(){
    if(!this.disabled && this.show){
      return "show"
    } else {
      return "hide"
    }
  }

  disabledClassIf(){
    return this.disabled ? "disabled" : ""
  }

  ngOnDestroy(): void {
    console.log(`=== destroy panel: ${this.title} | ${this.show}`)
  }

}
