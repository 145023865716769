export class CommonModel {

    notes: string = ''

    evaluateurHistoriques = new Map()
    lastUserStatut: any
    lastUserNCorAV: any
    lastUserLeveeEcart: any
    lastUserOpportunity: any
    lastUserOpportunityRealized: any
    lastUserRevision: any

    constructor(){}

    initCommon(_currentEE: any){
        this.asMap(_currentEE.evaluateurHistoriques)
        this.lastUserStatut = this.evaluateurHistoriques.get('changementStatut') || _currentEE.lastUserStatut
        this.lastUserNCorAV = this.evaluateurHistoriques.get('aEvaluerOrNonConforme') || _currentEE.lastUserNCorAV
        this.lastUserLeveeEcart = this.evaluateurHistoriques.get('leverEcart') || _currentEE.lastUserLeveeEcart
        this.lastUserOpportunity = this.evaluateurHistoriques.get('creerOpportunite') || _currentEE.lastUserOpportunity
        this.lastUserOpportunityRealized = this.evaluateurHistoriques.get('realiserOpportunite') || _currentEE.lastUserOpportunityRealized
        this.lastUserRevision = this.evaluateurHistoriques.get('reviser') || _currentEE.lastUserRevision
    }

    asMap(list: any) { 
        // instanceof test for minimalForm case ("double" init)
        if(list != undefined && Object.keys(list).length != 0){
            if(!(list instanceof Map)){
                list.forEach(el => {
                    this.evaluateurHistoriques.set(el.action, el.evaluateur != undefined ? el.evaluateur.prenom + " " + el.evaluateur.nom : undefined)
                })
            } else {
                this.evaluateurHistoriques = list
            }
        }
    }

}
