import { Component, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonComponent } from 'app/shared/common/common.component';
import { ToEvaluateModel } from './to-evaluate.model';

@Component({
  selector: 'app-ee-to-evaluate',
  templateUrl: './to-evaluate.component.html',
  styleUrls: ['./to-evaluate.component.scss']
})
export class ToEvaluateComponent extends CommonComponent implements OnInit {

  @Input() @Output('model') model: ToEvaluateModel
  // @Input() formAttr: any
  @Input('statut') statut: number
  @Input('initialStatut') initialStatut: number 

  constructor(public translate: TranslateService) {
    super(translate)
   }

  ngOnInit(): void {
  }

  onDataFieldChange(e: any, form: any){
    form[e.field] = e.value
  }

  switchToAE(){
    return (this.initialStatut != 1 && this.statut == 1)
  }

}
