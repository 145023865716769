import { Component, Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EvaluationExigenceFormService } from '../form.service';
import { NotCompliantComponent } from '../not-compliant/not-compliant.component';

@Component({
  selector: 'app-ee-to-check',
  templateUrl: './to-check.component.html',
  styleUrls: ['./to-check.component.scss']
})

@Injectable()
export class ToCheckComponent extends NotCompliantComponent implements OnInit {

  constructor(public eeFormService: EvaluationExigenceFormService, public translate: TranslateService) {
    super(eeFormService, translate)
   }

  ngOnInit(): void {
    super.ngOnInit()
  }

}
