import { Component, OnInit, EventEmitter, Output, Input, ViewChild, SimpleChanges } from '@angular/core';
import { DxDataGridComponent, DxDropDownBoxComponent } from "devextreme-angular";
import { ResponsableActionService } from 'app/services/responsable-action.service';
import { AppConfig } from 'app/app.config';
import { TranslateService } from '@ngx-translate/core';
import { QSE_LAST_LANGUE } from 'app/services/user.service';

@Component({
  selector: 'app-responsable-selecteur',
  templateUrl: './responsable-selecteur.component.html',
  styleUrls: ['./responsable-selecteur.component.scss']
})
export class ResponsableSelecteurComponent implements OnInit {


  @ViewChild('dropBox') dropBox: DxDropDownBoxComponent;
  @ViewChild('respGrid') respGrid: DxDataGridComponent;
  @Output() responsableUpdated = new EventEmitter();
  @Input('selectedSite') selectedSite: any = undefined;
  @Input('selectedResponsable') selectedResponsable: any = undefined;
  @Input('defaultMessage') nomResponsable: any = "Responsable de l'action";


  // datasources
  respDataSource: any;


  
  _gridBoxValue: number = 1;
  _gridSelectedRowKeys: number[] = [1];



  constructor(private responsableService: ResponsableActionService, public translate: TranslateService) {
    this.respDataSource = responsableService.getDataSourceSite();
  }

  reset() {
    this.selectedResponsable = undefined;
  }

  ngOnInit() {
    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
       if (this.selectedResponsable == 0) this.selectedResponsable = undefined;
//    console.log("site-selecteur.component : ngOnInit()");
  }

  ngAfterViewInit() {
   // Trick for enabling site refreshing when changing client for the very first time
  /*
   setTimeout(() => {
    this.dropBox.instance.open();
   this.dropBox.instance.close();
  }, 0);
*/  
}



  ngOnChanges(changes: SimpleChanges) {
console.log("ResponsableSelecteurComponent:ONCHANGE:", changes);
    //const client: SimpleChange = changes.selectedClient;

  
    if (changes.selectedSite) {
  
      this.responsableService.sitePredefini[0] = this.selectedSite;

      if (this.respGrid && this.respGrid.instance && this.selectedSite) {
     //   console.log("reponsable-selecteur: this.selectedSite=", this.selectedSite);
       //  this.respGrid.instance.filter([["actif","=",1], "AND", ["site_id", "=", this.selectedSite]]);
         this.respGrid.instance.filter([this.selectedSite]);
      //  this.respGrid.instance.getDataSource().reload();
       
      }
    }

    if(changes.selectedResponsable) {
     if(changes.selectedResponsable.currentValue==undefined && changes.selectedResponsable.previousValue && !changes.selectedResponsable.firstChange) {
  //    console.log("ResponsableSelecteurComponent PREVENTING CHANGE");
      return;
     }
       if(changes.selectedResponsable.currentValue==0) {
         this.selectedResponsable = undefined;
         this._gridBoxValue =1 ; 
         this._gridSelectedRowKeys =  [1];
       }
     }
    
    if(changes.defaultMessage)
      this.nomResponsable = changes.defaultMessage;
    
  }





  dropdown_syncGridSelection(event) {
    if (event.value === null) {
     if(AppConfig.settings.trace)  console.log("Responsable CLEARED !!!");

      this.responsableUpdated.emit(undefined);
      this.nomResponsable = "Aucun";
      this.selectedResponsable = undefined;
      this._gridBoxValue =1 ; 
      this._gridSelectedRowKeys =  [1];
    }
  }

  dropdown_gridItemSelectionChanged(event) {
    if (!this.respGrid) return;
    // console.log("dropdown_gridItemSelectionChanged", event);
    this.selectedResponsable = this.respGrid.instance.getSelectedRowKeys();

    this._gridBoxValue = this.selectedResponsable.length && this.selectedResponsable[0] || null;
    this._gridSelectedRowKeys = this.selectedResponsable;

    if (this.dropBox && this.dropBox.instance && event.selectedRowKeys.length > 0) {
      this.dropBox.instance.close();
      this.nomResponsable = event.selectedRowsData[0].prenom + " "+ event.selectedRowsData[0].nom;
    }
    this.responsableUpdated.emit(this.respGrid.instance.getSelectedRowsData()[0]);
  }


  get gridBoxValue(): number {
    return this._gridBoxValue;
  }

  set gridBoxValue(value: number) {
    this._gridSelectedRowKeys = value && [value] || [];
    this._gridBoxValue = value;
  }

  get gridSelectedRowKeys(): number[] {
    return this._gridSelectedRowKeys;
  }

  set gridSelectedRowKeys(value: number[]) {
    this._gridBoxValue = value.length && value[0] || null;
    this._gridSelectedRowKeys = value;
  }

  gridBox_displayExpr(item) {
    return item && (item.prenom + " " + item.nom);
  }

}
