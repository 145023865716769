import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { userHeaderInfo } from 'app/services/commons';
import editors from './commons.editors'
import { TranslateService } from '@ngx-translate/core';
import { QSE_LAST_LANGUE } from 'app/services/user.service';

@Component({
  selector: 'app-ee-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss']
})


export class CommonComponent implements OnInit {

  @Input() currentSite: any
  @Input() public editMode: number
  @Input() formAttr: any
  @Output('onRevision') event_: EventEmitter<any> = new EventEmitter<any>();
  public iso: boolean

   public prioriteDataSource = [
    { value: 0, label: "0 (Inexistante)" },
    { value: 1, label: "1 (Faible)" },
    { value: 2, label: "2 (Modérée)" },
    { value: 3, label: "3 (importante)" }
  ];

  
  // Template editors
  fourItemsOptions = editors.fourItemsOptions
  priorityDSOptions = {   items: this.prioriteDataSource, displayExpr:"label", valueExpr:"value" }
  readOnlyNumberOptions = editors.readOnlyNumberOptions
  numberOptions = editors.numberOptions
  percentNumberOptions = editors.percentNumberOptions
  amountNumberOptions = editors.amountNumberOptions

  readOnlyDateOptions = editors.readOnlyDateOptions
  dateOptions = editors.dateOptions
  initDateOptions = editors.initDateOptions
  textAreaOptions = editors.textAreaOptions
  readonlyText = editors.readonlyText

  constructor(public translate: TranslateService) {

    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
    this.translate.get(
      [
        'audit-editeur-priorite-0*label',  
        'audit-editeur-priorite-1*label',
        'audit-editeur-priorite-2*label',
        'audit-editeur-priorite-3*label'
      ]
    )
      .subscribe(val => {
        this.prioriteDataSource[0].label = val['audit-editeur-priorite-0*label'];
        this.prioriteDataSource[1].label = val['audit-editeur-priorite-1*label'];
        this.prioriteDataSource[2].label = val['audit-editeur-priorite-2*label'];
        this.prioriteDataSource[3].label = val['audit-editeur-priorite-3*label'];
      });

  }

  ngOnInit(): void {
    this.iso = this.editMode == 3
  }

  dateConstatOptions() {
    return userHeaderInfo[0] == 'A' ? this.dateOptions : this.readOnlyDateOptions
  }

}
