import { CommonModel } from "app/shared/common/common.model"

export class CompliantModel extends CommonModel {

    initialOpportuniteFlag: number

    dateConstat: Date = new Date()
    dateValidite: Date
    dateResolution: Date 
    montant: number
    actions: string
    dateRevision: Date = null

    moyens: string
    service: number

    opportuniteFlag: boolean = false
    opportunite: string
    dateOuvertureOpportunite: Date 
    dateLimiteOpportunite: Date
    prioriteOpportunite: number
    responsableOpportunite: number
    serviceOpportunite: number

    efficacite: number = 0
    dateSaisieEfficacite: Date

    dateRealisationOpportunite: Date 
    opportunityRealized: boolean =false

    responsable: number
    nomResponsable: any

    modeleAction: boolean = false
    
    constructor(){
        super()
    }

    init(_currentEE: any){
        this.initialOpportuniteFlag = _currentEE.opportuniteFlag

        this.dateConstat = _currentEE.dateConstat 
        this.notes = _currentEE.notes 
        this.dateValidite = _currentEE.dateValidite
        this.dateResolution = _currentEE.dateResolution
        this.montant = _currentEE.montant
        this.actions = _currentEE.actions
        this.responsable = _currentEE.responsable
        this.nomResponsable = _currentEE.nomResponsable
        this.dateRevision = _currentEE.dateRevision

        this.moyens = _currentEE.moyens
        this.service = _currentEE.service

        this.opportuniteFlag = _currentEE.opportuniteFlag
        this.opportunite = _currentEE.opportunite
        this.dateOuvertureOpportunite = _currentEE.dateOuvertureOpportunite
        this.dateLimiteOpportunite = _currentEE.dateLimiteOpportunite
        this.prioriteOpportunite = _currentEE.prioriteOpportunite
        this.responsableOpportunite = _currentEE.responsableOpportunite
        this.serviceOpportunite = _currentEE.serviceOpportunite

        this.efficacite = _currentEE.efficacite
        this.dateSaisieEfficacite = _currentEE.dateSaisieEfficacite
        this.dateRealisationOpportunite = _currentEE.dateRealisationOpportunite
        this.opportunityRealized = _currentEE.dateRealisationOpportunite !== undefined && _currentEE.dateRealisationOpportunite !== null

        this.modeleAction = _currentEE.modeleAction

        super.initCommon(_currentEE)

        return this
    }

}
