import { Component, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProgressionService } from 'app/services/progression.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-progression',
  templateUrl: './progression.component.html',
  styleUrls: ['./progression.component.scss']
})
export class ProgressionComponent implements OnInit, OnDestroy {

  @Input() message: string;
  @Input() taskToken: string = undefined;
  @Input() refreshFrequency: number = 1000; /* en milliseconds */
  @Input() value: number = 0;

  intervalId: number;

  constructor(private progressionService: ProgressionService) {
    // if(this.taskToken) this.intervalId = setInterval(() => this.timer(), this.refreshFrequency);
  }

  token() {
    return this.taskToken;
  }

  timer() {
    this.progressionService.progress(this.taskToken).then(r => {
      let p = r.type;
      //console.log("timer: progress=" + p);
      if (p < 0) {
        notify("Erreur pour : " + this.message, "error", 2000);
        clearInterval(this.intervalId);
        return;
      }

      this.value = p;

      if (p >= 100) {
        clearInterval(this.intervalId);
        return;
      }
    });
  }

  format(value) {
    return Math.round(value*100) + '%';
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.message && this.message &&  this.taskToken) {
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => this.timer(), this.refreshFrequency);
    } 
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId)
  }
  
}
