import { CommonModel } from "app/shared/common/common.model"

export class NotCompliantModel  extends CommonModel {

    dateConstat: Date = new Date()

    actions: string = ''
    moyens: string = ''
    priorite: number
    dateLimite: Date
    facilite: boolean = false
    responsable: number
    nomResponsable: string
    notifyResponsible: boolean = false

    frequence: number
    gravite: number
    hierarchisation: number
    montant: number
    niveauRealisation: number
    dateRealisation: Date
    service: number

    modeleAction: boolean = false

    constructor(){
        super()
    }

    init(_currentEE: any){
        this.dateConstat = _currentEE.dateConstat

        this.actions = _currentEE.actions 
        this.moyens = _currentEE.moyens
        this.notes = _currentEE.notes
        this.priorite = _currentEE.priorite
        this.dateLimite = _currentEE.dateLimite
        this.facilite = _currentEE.facilite
        this.responsable = _currentEE.responsable
        this.nomResponsable = _currentEE.nomResponsable

        this.frequence = _currentEE.frequence
        this.gravite = _currentEE.gravite
        this.hierarchisation = this.priorite * this.gravite * this.frequence
        this.montant = _currentEE.montant
        this.niveauRealisation = _currentEE.niveauRealisation
        this.dateRealisation = _currentEE.dateRealisation
        this.service = _currentEE.service

        this.modeleAction = _currentEE.modeleAction

        super.initCommon(_currentEE)

        return this
    }

}
