<div class="motif-form">
    <dx-form #mfForm_ id="mfForm" [(formData)]="motifObj" showColonAfterLabel="false" onDataFieldChange="onDataFieldMotifChange($event)" [colCount]="1"
        labelLocation="top">

        <dxi-item itemType="group"  [colSpan]="2" [colCount]="2">
            <dxi-item dataField="motif" editorType="dxTextArea" [editorOptions]="editor()"
                    [colSpan]="2">
                <dxo-label [text]="label()"></dxo-label>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
        </dxi-item>

    </dx-form>
</div>