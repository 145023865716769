import { RouteReuseStrategy } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';
import { ComponentRef, Injectable } from '@angular/core';
import { AppConfig } from './app.config';

interface DetachedRouteHandleExt extends DetachedRouteHandle {
    componentRef: ComponentRef<any>;
}

export class CacheRouteReuseStrategy implements RouteReuseStrategy {

    storedRouteHandles = new Map<string, DetachedRouteHandleExt>()

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // let res = false
        // if(future.routeConfig && curr.routeConfig){
        //     res = !this.isLogin(future) && !this.isLogin(curr) && future.routeConfig == curr.routeConfig
        // }
        // return res
        let res = false
        if(curr.routeConfig && future.routeConfig){
            res = this.mustBeCached(curr) && this.mustBeCached(future) && future.routeConfig == curr.routeConfig
        }
        return res
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        // if(this.isLogin(route)){
        //     this.resetReusableRoutes()
        // }
        // return !this.isLogin(route) && this.storedRouteHandles.has(this.getPath(route))
        return this.mustBeCached(route) && this.storedRouteHandles.has(this.getPath(route))
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandleExt | null {
        // let res = null;
        // if(!this.isLogin(route)){
        //     res = this.storedRouteHandles.get(this.getPath(route))
        // }
        // return res == undefined ? null : res
        let res = null
        if(this.mustBeCached(route)){
            res = this.storedRouteHandles.get(this.getPath(route))
        }
        return res == undefined ? null : res
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        // return !this.isLogin(route)
        return this.mustBeCached(route)
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandleExt): void {
        // if(!this.isLogin(route)){
        //     this.storedRouteHandles.set(this.getPath(route), handle)
        // }
        if(this.mustBeCached(route)){
            this.storedRouteHandles.set(this.getPath(route), handle)
        }
    }

    resetReusableRoutes(){
        if (AppConfig.settings.trace) console.log(`==== before clear: ${this.storedRouteHandles.size}`)
        this.storedRouteHandles.forEach((handle, key, map) => {
            if(handle != null){
                handle.componentRef.destroy()
            }
        })
        this.storedRouteHandles.clear()
    }

    private getPath(route: ActivatedRouteSnapshot): string {
        let res = ''
        route.pathFromRoot.forEach(
            route_ => {
                if (route_.routeConfig !== null && route_.routeConfig.path !== null) {
                    if (route_.routeConfig.path === '') {
                        res = res + '/' + route_.data['title']
                    } else {
                        // res = res + '/' + route_.routeConfig.path
                        res = res + '/' + route_.url
                    }
                }
            }
        )
        return res
    }

    private mustBeCached(route: ActivatedRouteSnapshot): boolean {
        return this.getPath(route).includes('revue') /*|| this.getPath(route).includes('textes')*/
    }

}
