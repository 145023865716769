import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QSE_USER_INFO, userInformation } from 'app/services/user.service';
import { ConnectAsService } from './connect-as.service';

@Component({
  selector: 'app-connect-as',
  templateUrl: './connect-as.component.html',
  styleUrls: ['./connect-as.component.scss']
})
export class ConnectAsComponent implements OnInit {

  @Input() userName : string

  constructor(private connectAsService: ConnectAsService,
    private router: Router) { }

  ngOnInit(): void {
  }

  connectAs(){ 
    this.generateTmpPassword(this.userName).then(tmpPwd => {
    
    let user: any = JSON.parse(sessionStorage.getItem(QSE_USER_INFO))
    localStorage.setItem('adminAsUser', user.prenom + " " + user.nom)
    localStorage.setItem('adminAsUserId', user.id)

    localStorage.setItem('tmpUserName', this.userName)
    localStorage.setItem('tmpPwd', tmpPwd)
    
    this.routeInNewTab('/')
    })
  }

  generateTmpPassword(userName: string): any {
    return this.connectAsService.generateTmpPassword(userName)
  }

  private routeInNewTab(page: string){
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`${page}`])
    )
  
    window.open(url, '_blank')
  }

}


