
<div *ngFor="let token of tasksTokens; let i = index">
    <div class="progress-info">
        {{messages[token]}}
    </div>
    <dx-progress-bar #progressBar
        id="progress-bar-status"
        width="100%"
        [class.complete]="progressBar.value >= 100"
        [min]="0"
        [max]="100"
        [statusFormat]="format"
        [value]="values[token]">
    </dx-progress-bar>
</div>