import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
import { QSE_LAST_LANGUE } from 'app/services/user.service';

@Component({
  selector: 'app-profil-motif',
  templateUrl: './motif.component.html',
  styleUrls: ['./motif.component.scss']
})

export class MotifComponent implements OnInit {

  @Input() motifObj = {motif: null}
  @Input() embeded: boolean = false
  @Input() mode: string = "add" //"add", "update" or "remove"

  translate: any

  constructor(public translate_: TranslateService) {
    this.translate = translate_
   }

  ngOnInit(): void {
    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
  }

  onDataFieldMotifChange(event: any){
    this.motifObj.motif = event.value
  }

  label(){
    let l_: string
    switch (this.mode) {
      case "add":
        l_ = this.translate.instant('profil-motif-form-label*Motif-add') 
        break;
      case "update":
        l_ = this.translate.instant('profil-motif-form-label*Motif-update')
        break;
      case "delete":
        l_ = this.translate.instant('profil-motif-form-label*Motif-delete')
        break;
    }
    return l_
  }

  editor(){
    return !this.embeded ? {height: '300px', autoResizeEnabled: true} : {height: '100px', autoResizeEnabled: true}
  }

}
