import { Injectable } from '@angular/core';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { jsToSQLFilter, replaceInFilter, getHttpHeaders } from 'app/services/commons';
import { saveAs } from 'file-saver';
import { AppConfig } from 'app/app.config';
import { TranslateService } from '@ngx-translate/core';
import { QSE_LAST_LANGUE } from './user.service';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EvaluationConformiteService {

  dataSource: any;
  ouvertsDataSource: any;
  tlabels: any = {};

  selectedAuditSubject = new Subject<any>()

  dupliquerAudit(audit_id: any, token:any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/duplicate/" + audit_id +"?tasktoken="+token, { headers: getHttpHeaders() }).toPromise();
  }

  verifAndCorrectAll() {

    return this.http.get<any>(AppConfig.settings.api + "/api/profil/fixall", { headers: getHttpHeaders() }).toPromise();
  }

  coherencerAudit(audit_id: any) {
    if (audit_id)
      return this.http.get<any>(AppConfig.settings.api + "/api/audit/coherence/" + audit_id, { headers: getHttpHeaders() }).toPromise();
  }

  reconstitueProfil(audit_id: any) {
    if (audit_id)
      return this.http.get<any>(AppConfig.settings.api + "/api/audit/reconstitueprofil/" + audit_id, { headers: getHttpHeaders() }).toPromise();
  }

  coherencerAuditCloture(audit_id: any) {
    if (audit_id)
      return this.http.get<any>(AppConfig.settings.api + "/api/audit/coherencecloture/" + audit_id, { headers: getHttpHeaders() }).toPromise();
  }

  coherencerAllLastAudits(skip) {
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/coherences/all/?skip="+skip, { headers: getHttpHeaders() }).toPromise();
  }
  coherencerAllClotures() {
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/coherencesclotures/all/", { headers: getHttpHeaders() }).toPromise();
  }


  importAudit(values: any) {
    return this.http.post<any>(AppConfig.settings.api + "/api/audit/import", values, { headers: getHttpHeaders() }).toPromise();
  }

  exportAudit(audit_id: any, vierge: boolean, locked: boolean, language: string, tasktoken: string) {
    // return this.http.get<any>(environment.api +"/api/audit/export/"  + audit_id + "?vierge=" + (vierge?"true": "false"), { headers: getHttpHeaders()}).toPromise();
    return this.http.get(AppConfig.settings.api + "/api/audit/export/" + audit_id + "?vierge=" + (vierge ? "true" : "false") + "&locked=" + (locked ? "true" : "false") + "&langue=" + language + "&tasktoken=" + tasktoken, { headers: getHttpHeaders(), responseType: 'blob' }
    ).toPromise().then(response => {
      saveAs(response, "audit" + audit_id + ".xlsx");
      notify("Votre rapport d'audit est prêt.");
    }).catch(error => {
      console.log("ERROR=", error);
    });

  }

  convertUsages2TaxoAudit(audit_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/usages2taxo/" + audit_id, { headers: getHttpHeaders() }).toPromise();
  }



  statsAudit(audit_id: any) {
    //  console.log("Calling /api/audit/stats/" + audit_id);
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/stats/" + audit_id, { headers: getHttpHeaders() }).toPromise();
  }



  findAuditV2(auditv3_id: any) {
    //   console.log("Calling /api/qsev2/findaudit/" + auditv3_id);
    return this.http.get<any>(AppConfig.settings.api + "/api/qsev2/findaudit/" + auditv3_id, { headers: getHttpHeaders() }).toPromise();
  }

  resurectEvalV2(auditv3_id: any, usage_id: any, auditv2_id: any) {
    //   console.log("Calling /api/qsev2/findaudit/" + auditv3_id);
    return this.http.get<any>(AppConfig.settings.api + "/api/qsev2/recupaudit/refeval/" + auditv3_id + "/" + usage_id + "/" + auditv2_id, { headers: getHttpHeaders() }).toPromise();
  }

  resurectAllEvalV2(auditv3_id: any, auditv2_id: any) {
    //   console.log("Calling /api/qsev2/findaudit/" + auditv3_id);
    return this.http.get<any>(AppConfig.settings.api + "/api/qsev2/recupaudit/all/" + auditv3_id + "/" + auditv2_id, { headers: getHttpHeaders() }).toPromise();
  }


  compareAuditsV3V2() {
    return this.http.get(AppConfig.settings.api + "/api/qsev2/profilscmp/", { headers: getHttpHeaders(), responseType: 'blob' }

    ).toPromise().then(response => {
      saveAs(response, "rapport-comparaison.csv");
      notify("Le rapport de comparaison d'audit v3/V2 est prêt.");
    }).catch(error => {
      console.log("ERROR=", error);
    });

  }



  listAuditsForRecoverProfilFromSite(site_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/recover/" + encodeURIComponent(site_id), { headers: getHttpHeaders() }).toPromise();
  }

  // Mantis 335
  listAuditsForCollectFromAnotherProfil(site_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/collectfromanotherprofile/" + encodeURIComponent(site_id), { headers: getHttpHeaders() }).toPromise();
  }


  recoverAuditFromAnotherAudit(audit_id: any, auditref_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/recoverexigence/" + encodeURIComponent(audit_id) + "/from/" + encodeURIComponent(auditref_id), { headers: getHttpHeaders() }).toPromise();
  }


  // Mantis 335
  recoverAuditFromAnotherProfilAudit(audit_id: any, auditref_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/recoverfromotherprofile/" + encodeURIComponent(audit_id) + "/from/" + encodeURIComponent(auditref_id), { headers: getHttpHeaders() }).toPromise();
  }


  recupFusion040421(audit_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/fusion040421/" + encodeURIComponent(audit_id), { headers: getHttpHeaders() }).toPromise();
  }

  importXLSXRedonline(values: any) {
    return this.http.post<any>(AppConfig.settings.api + "/api/audit/importredonline", values, { headers: getHttpHeaders() }).toPromise();
  }

  importXLSXAmadeo(values: any) {
    return this.http.post<any>(AppConfig.settings.api + "/api/audit/importamadeo", values, { headers: getHttpHeaders() }).toPromise();
  }
  importXLSXConformiteo(values: any) {
    return this.http.post<any>(AppConfig.settings.api + "/api/audit/importconformiteo", values, { headers: getHttpHeaders() }).toPromise();
  }

  downloadLastRedonlineImportReport() {
    // return this.http.get<any>(environment.api +"/api/audit/export/"  + audit_id + "?vierge=" + (vierge?"true": "false"), { headers: getHttpHeaders()}).toPromise();
    return this.http.get(AppConfig.settings.api + "/api/audit/download-report-last-import-redonline" ,
      { headers: getHttpHeaders(), responseType: 'blob' }

    ).toPromise().then(response => {
      saveAs(response, "rapport-import-redonline.xlsx");
      notify("Votre rapport d'import redonline est prêt.");
    }).catch(error => {
      console.log("ERROR=", error);
    });

  }


  downloadLastAmadeoImportReport() {
    // return this.http.get<any>(environment.api +"/api/audit/export/"  + audit_id + "?vierge=" + (vierge?"true": "false"), { headers: getHttpHeaders()}).toPromise();
    return this.http.get(AppConfig.settings.api + "/api/audit/download-report-last-import-amadeo" ,
      { headers: getHttpHeaders(), responseType: 'blob' }

    ).toPromise().then(response => {
      saveAs(response, "rapport-import-amadeo.xlsx");
      notify("Votre rapport d'import amadeo est prêt.");
    }).catch(error => {
      console.log("ERROR=", error);
    });

  }

  downloadLastConformiteoImportReport() {
    // return this.http.get<any>(environment.api +"/api/audit/export/"  + audit_id + "?vierge=" + (vierge?"true": "false"), { headers: getHttpHeaders()}).toPromise();
    return this.http.get(AppConfig.settings.api + "/api/audit/download-report-last-import-confomiteo" ,
      { headers: getHttpHeaders(), responseType: 'blob' }

    ).toPromise().then(response => {
      saveAs(response, "rapport-import-confomiteo.xlsx");
      notify("Votre rapport d'import confomiteo est prêt.");
    }).catch(error => {
      console.log("ERROR=", error);
    });

  }


  copyEvalsFromAnotherAudit(audit_id: any, auditref_id: any, processOnlyCandSO:boolean) {
    if(processOnlyCandSO)
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/recovercopyevalscso/" + encodeURIComponent(audit_id) + "/from/" + encodeURIComponent(auditref_id) , { headers: getHttpHeaders() }).toPromise();
      else
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/recovercopyevals/" + encodeURIComponent(audit_id) + "/from/" + encodeURIComponent(auditref_id) , { headers: getHttpHeaders() }).toPromise();
  }



  diffAudits(audit_id: any, auditref_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/diff/" + encodeURIComponent(audit_id) + "/" + encodeURIComponent(auditref_id), { headers: getHttpHeaders() }).toPromise();
  }


  fusionAudits(sources: any, target: any, nom:any, site) {
    if(AppConfig.settings.trace) {
      console.log("Fusion sources="+sources);
      console.log("Fusion target="+target);
      console.log("Fusion nom="+nom);
      console.log("Fusion site="+site);
    }
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/fusion/?src=" + encodeURIComponent(sources)
     + "&tgt=" + encodeURIComponent(target)  + "&nom=" + encodeURIComponent(nom)+ "&site=" + encodeURIComponent(site), { headers: getHttpHeaders() }).toPromise();
  }

  listAuditsOuverts(site_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/ouverts/" + encodeURIComponent(site_id), { headers: getHttpHeaders() }).toPromise();
  }

  compareAuditWithProfile(site_id: any, audit_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/audit/compare/" + encodeURIComponent(site_id) + "/" +  encodeURIComponent(audit_id), { headers: getHttpHeaders() }).toPromise()
    .catch(error => {
      console.log("ERROR: ", error)
      notify("ERROR", error)
    })
  }





  constructor(private http: HttpClient, public translate: TranslateService) {

    let SERVICE_URL = AppConfig.settings.api + "/api/audit/";

    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
    this.translate.get(
      [
        'services-common-notify*màj',
      ]
    )
      .subscribe(val => {
        this.tlabels = val;
      });

    let zis = this;

    /*
    this.ouvertsDataSource = new CustomStore(
      {
        key: "id",
        load: function (loadOptions: any) {
          var filter = "?filter=0";
        
          if (loadOptions.filter) {
            filter = "?filter=" + encodeURIComponent(loadOptions.filter);
          } 
          
          return http.get<any>(SERVICE_URL + filter, { headers: getHttpHeaders() }).toPromise()
            .then(response => {
              return response;
            });
        },
      }
    );
*/

    this.dataSource = new CustomStore(
      {
        key: "id",
        load: function (loadOptions: any) : any {
          var filter = "?filter=0";
          //  console.log("EvaluationConformiteService ", loadOptions);
          if (loadOptions.filter) {
            filter = "?filter=" + encodeURIComponent(loadOptions.filter);
          } else
            if (loadOptions.multisite) {
              filter = "?multisite=" + encodeURIComponent(loadOptions.multisite);
            }

          return http.get<any>(SERVICE_URL + filter, { headers: getHttpHeaders() }).toPromise()
            .then(response => {
              // console.log("---------------> EvaluationConformiteService SERVER RESPONSE: ", response);
              return response;
            });
        },

        // totalCount: function(loadOptions:any) { return 0;},

        byKey: function (key) {
          //  console.log("byKey: " + SERVICE_URL + encodeURIComponent(key));
          return http.get(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        },
        insert: function (values:any) {

          //   console.log("EvaluationConformiteService CREATION: ", values);
          //  console.log("insert: post " + SERVICE_URL);
          
          return http.post<any>(SERVICE_URL, values, { headers:  getHttpHeaders()}).toPromise().then(response => {
            values.id = response.key;
            return {
              values: response.values,
              key: response.key
            }
          })
        },
        update: function (key, values) {
          //  console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
          //  console.log("update values:", values);
          return http.put<any>(SERVICE_URL + encodeURIComponent(key), values, { headers: getHttpHeaders() }).toPromise().then(response => {

            return {
              result: response.result,
              key: response.key
            }
          });

        },
        remove: function (values) {
          // Need to refresh cache after that ?
          // console.log("EvaluationConformiteService remove DELETE: " + SERVICE_URL + encodeURIComponent(key));
          return http.delete<any>(SERVICE_URL + encodeURIComponent(values.key), { headers: getHttpHeaders(), body: values.tasktoken }).toPromise();
        }
      }
    );

  }

  getRestDataSource() {
    return this.dataSource;
  }

  
}