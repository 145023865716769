import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxSelectBoxComponent } from 'devextreme-angular'
import { UserService, userInformation, QSE_USER_INFO, QSE_LAST_SITE, QSE_LAST_LANGUE, QSE_LAST_DIVISION } from 'app/services/user.service';
import { userHeaderInfo } from 'app/services/commons';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteChangeService } from 'app/site-change.service';
import { AppConfig } from 'app/app.config';
import { locale, loadMessages } from 'devextreme/localization';
import { AdministrateurService } from 'app/services/administrateur.service';
import notify from 'devextreme/ui/notify';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

// https://stackblitz.com/edit/angular-page-reload
// https://medium.com/engineering-on-the-incline/reloading-current-route-on-click-angular-5-1a1bfc740ab2
export class NavbarComponent {

    @ViewChild('siteSelector') siteSelector: DxSelectBoxComponent;
    @ViewChild('clientSelector') clientSelector: DxSelectBoxComponent;
    currentLang = 'fr';
    toggleClass = 'ft-maximize';
    toggleCompact = 'ft-align-justify';
    userUI = false;
    isGroup = false;
    whileInit = true;

    siteDatasource = [];
    clientDatasource = [];
    selectSite: any = undefined;
    selectClient: any = undefined;
    infoUser: any = {};
    droits: string = "";
    droitsLabels: any = [];
    watchMessages: any = undefined;
    notifDataSource: any = [];
    selectedLanguageFlag = "./assets/img/flags/fr.png";
    nomClient = "";
    notifType: any = ["Notification", "Réponse support", "Nouvelle tâche", "Activité support"];
    notifTypeCSS: any = ["warning", "success", "success", "danger"];
    notifTypeLink: any = ["accueil", "support", "admin-accueil", "admin-support"];
    changeLabel: string = "";

    constructor(
        public translate: TranslateService,
        private router: Router,
        private userService: UserService,
        private siteChangeService: SiteChangeService,
        private adminService: AdministrateurService) {
        const browserLang: string = translate.getBrowserLang();
        this.userUI = !this.userService.isAdminUserPersisted();
        translate.use(browserLang.match(/fr|en/) ? browserLang : 'fr');


       


    }


    determineDroits(selsite) {
        if (!selsite) return;
        this.translate.get(
            [
                'navbar-droit*ecriture',
                'navbar-droit*lecture',
                'navbar-droit*mailing',
            
                'navbar-na*changesite',
                'navbar-na*changedivisionsite'
            ]
        )
            .subscribe(val => {
                this.droitsLabels = val;
                if (this.isGroup) 
            this.changeLabel = this.droitsLabels['navbar-na*changedivisionsite'];
            else
            this.changeLabel = this.droitsLabels['navbar-na*changesite'];

            });

        var d = this.infoUser.droits[selsite];

        if (d == 2 || d == 4 || d == 6 || d == 8) {
            this.droits = this.droitsLabels['navbar-droit*ecriture']; // "Écriture"; 
        }
        else
        // lecture
        {
            this.droits = this.droitsLabels['navbar-droit*lecture']; // "Lecture"; 
        }
        if (d == 1 || d == 2 || d == 5 || d == 6) {
            this.droits = this.droits + " +" + this.droitsLabels['navbar-droit*mailing'];
        } // mailing  
        else
        // no mailing
        { }
    }

    ngOnInit() {

     //   console.log("NavbarComponent : ENTERING ngOnInit()" );

        this.whileInit = true;
        let lg = sessionStorage.getItem(QSE_LAST_LANGUE);
        this.setFlag(lg);
        this.translate.use(lg);

        this.infoUser = JSON.parse(sessionStorage.getItem(QSE_USER_INFO));
        this.isGroup = this.infoUser.isGroup;
        if (this.userUI) this.nomClient = this.infoUser.nomClient;

        userHeaderInfo[7] =  parseInt(sessionStorage.getItem(QSE_LAST_DIVISION));
        userHeaderInfo[2] =  parseInt(sessionStorage.getItem(QSE_LAST_SITE));

       
     /*   console.log("infoUser=", this.infoUser);
        console.log("infoUser.isGroup=", this.infoUser.isGroup);
        console.log("infoUser.sites=", this.infoUser.sites);
        console.log("infoUser.selectSite=", this.infoUser.selectSite);
        */
        this.infoUser.selectSite = parseInt(sessionStorage.getItem(QSE_LAST_SITE));

        if (this.userUI) {
            if (this.isGroup) {
                this.changeLabel = this.droitsLabels['navbar-na*changedivisionsite'];
                // Construire la liste des divisions/clients à afficher
               /* var cmap = {}
                this.infoUser.sites.map(s => {
                    if (!(s.client in cmap)) {
                        cmap[s.client] = true;
                        this.clientDatasource.push({ id: s.client, label: s.nomClient });
                    }
                });
                */
                this.clientDatasource = this.infoUser.clients;
               ;
             
                this.selectClient = this.infoUser.selectClient;
                    // Déterminer le client sélectionné à partir du site sélectionné
                    this.infoUser.sites.map(s => {
                      //  console.log(s);
                        if (this.infoUser.selectSite == s.id) {
                            this.selectClient = s.client;
                            this.nomClient = s.nomGroupe;
                            userHeaderInfo[5] = s.hotline ? 1 : 0;
                        }
                    });

                   // console.log("selectClient derived from this.infoUser.selectSite=" + this.selectClient);

                    // Construire la liste filtrée des sites
                    this.siteDatasource = [];
                    this.infoUser.sites.map(s => {
                        if (s.client == this.selectClient) {   // Si cela correspond au cliebnt sélectionné, on ajout dans la liste
                          //  console.log("Site : " + s.nom  + "accountTimeActive="+s.accountTimeActive);
                            if (!(s.disabled || !s.active || !s.accountTimeActive))
                            this.siteDatasource.push({ id: s.id, label: s.label });
                        }
                    });
                   // console.log("this.siteDatasource set to : ", this.siteDatasource)

                    this.selectSite = this.infoUser.selectSite;
                    if( !  this.selectSite) {
                        this.selectSite = this.siteDatasource[0].id;
                        userHeaderInfo[2] = this.siteDatasource[0].id;
                     }
                    this.determineDroits(this.selectSite);

                    this.whileInit = false;

             //   });

            } else {
                this.changeLabel = this.droitsLabels['navbar-na*changesite'];

                this.siteDatasource = this.infoUser.sites;

                 // Si aucun dernier site défini
                if( ! userHeaderInfo[2]) {
                    userHeaderInfo[2] = this.siteDatasource[0].id;
                    //console.log("userHeaderInfo[2]=" + userHeaderInfo[2]);
                 }
               
               
                this.selectSite =  userHeaderInfo[2];
                this.determineDroits(this.selectSite);
                
            }

        }

     //   console.log("EXITING ngOnInit()" );
    }


    logout() {
        this.userService.logout();

        if (this.userUI)
            this.router.navigate(['pages/login']);
        else
            this.router.navigate(['pages/admin-login']);
    }

    ngAfterViewInit() {

        /*
        if(this.siteDatasource && this.siteDatasource.length<2 && this.siteSelector && this.siteSelector.instance)
           this.siteSelector.instance.option("disabled", true);
       */

        this.watchMessages = setInterval(() => { this.watchServerMessages() }, AppConfig.settings.notif_refresh_rate * 1000);


     
    }

 


    watchServerMessages() {
        // Pour un UTILISATEUR:
        // requete serveur : utilisateur_id, site_id: on vérifie :
        // 1) s'il y a des réponses récentes à des questions du support. Cas particulier : on est déjà sur la page support: il faut recharger la datasource
        // 2) s'il y a des notifications utilisateur/site depuis le dernier check-timestamp. Cas particulier : On est déjà sur la page d'accueil: recharge la datasource des notifications.
        if (userHeaderInfo[0] == "U") {

            this.userService.verifierDerniereNotificationsPourClient(userHeaderInfo[1], this.selectSite).then(r => {
                this.notifDataSource = r.data;
                // console.log("notifDataSource=" ,this.notifDataSource);
            })

        } else {
            if (this.userService) {
                if (!userHeaderInfo[1]) console.error("userHeaderInfo[1] is null ! ");
                this.userService.verifierDerniereNotificationsPourAdmin(userHeaderInfo[1]).then(r => {
                    this.notifDataSource = r.data;
                    //  console.log("notifDataSource=", this.notifDataSource);
                })
            }
            else
                ; //    console.log("this.userService undefined !");
        }

        // Pout un ADMINISTRATEUR
        // On vérifie:
        // 1) S'il y a des questions support/hotline récentes depuis le dernier check-timestamp. Cas particulier : on est déjà sur la page support: il faut recharger la datasource des questions.


        // Piste pour recharger les datasources : Event Listener comme pour le changement de site ?

    }


    // Au sein d'un groupe, changement de client (=division) et mise à jour de la liste des sites
    // accessible pour les droits de l'utilisateur connecté.
    clientSelector_onValueChanged(e: any) {
        // if(this.sleepingListeners) return;

        if (!e.value) return;
      //  console.log("whileInit="+this.whileInit + " clientSelector_onValueChanged", e)
        this.selectClient = e.value;
        this.infoUser.selectClient = this.selectClient;
        sessionStorage.setItem(QSE_LAST_DIVISION, this.selectClient);

       
        if( userHeaderInfo[7] ) {
            // Construire la liste filtrée des sites
            var ds = [];
            this.infoUser.sites.map(s => {
                if (s.client == this.selectClient) {   // Si cela correspond au cliebnt sélectionné, on ajout dans la liste
                    if (!(s.disabled || !s.active || !s.accountTimeActive))
                    ds.push({ id: s.id, label: s.label });
                }

            });
            this.siteDatasource = ds;
         
            this.selectSite = this.siteDatasource[0].id;
            sessionStorage.setItem(QSE_LAST_SITE, this.selectSite);
            userHeaderInfo[2] = this.selectSite;
            this.infoUser.selectSite = this.selectSite;
            var contratSite = 0;
            this.siteDatasource.map(s => {
                if (s.id == this.selectSite) {
                    contratSite = s.contrat;
                    userHeaderInfo[4] = contratSite;
                    userHeaderInfo[5] = s.hotline ? 1 : 0;
                }
            });
            // Informer les composants potentiellement impactés par le changement de site
            this.siteChangeService.lastSiteName = this.siteSelector.displayValue;
            this.siteChangeService.announceSiteChange(this.selectSite);

            this.userService.definirValeurDernierSiteUtilisateur(userHeaderInfo[1], this.selectSite).then(r => {
                // le dernier site utilisé par l'utilisateur connecté a été mémorisé.
            });
            this.determineDroits(this.selectSite);
        
        }

            userHeaderInfo[7] = this.selectClient;
        //     console.log("clientSelector_onValueChanged, new siteDatasource=", this.siteDatasource);

    }

    siteSelector_onValueChanged(e: any) {
        //  if(this.sleepingListeners) return;

        // Implémenter ici la prise en compte du changement de site en tenant compte du changement de contrat et de droit
        if (!e.value) return;
      //  console.log("whileInit="+this.whileInit + " siteSelector_onValueChanged", e)

        this.selectSite = e.value;
        this.infoUser.selectSite = this.selectSite;
        sessionStorage.setItem(QSE_LAST_SITE, this.selectSite);
        userHeaderInfo[2] = this.selectSite;

        var contratSite = 0;
        this.siteDatasource.map(s => {
            if (s.id == this.selectSite) {
                contratSite = s.contrat;
                userHeaderInfo[4] = contratSite;
                userHeaderInfo[5] = s.hotline ? 1 : 0;
            }
        });
        // Informer les composants potentiellement impactés par le changement de site
        this.siteChangeService.lastSiteName = this.siteSelector.displayValue;
        this.siteChangeService.announceSiteChange(this.selectSite);


        this.userService.definirValeurDernierSiteUtilisateur(userInformation.id, this.selectSite).then(r => {
            // le dernier site utilisé par l'utilisateur connecté a été mémorisé.
            if(AppConfig.settings.trace)
                console.log("Dernier site " + this.selectSite + " définit pour l'utilisateur " + userInformation.id);
        });
        this.determineDroits(this.selectSite);

    }
    ngOnDestroy() {
        if (this.watchMessages) {
            clearInterval(this.watchMessages);
        }
    }



    ChangeLanguage(language: string) {

        var prev = sessionStorage.getItem(QSE_LAST_LANGUE);
        if (prev == language) return;

        locale(language);
        sessionStorage.setItem(QSE_LAST_LANGUE, language);

        parent.document.location.reload();
        this.translate.use(language);
        this.setFlag(language);
       
        // Ici on enregistre le dernier langage sélectionné par l'utilisateur
        // Côté administrateur c'est le langage associé à l'adminitrateur qui est utilisé pour l'affichage de l'interface.
        if (this.userUI) {
           
            this.userService.definirValeurDerniereLangueUtilisateur(userInformation.id, language).then(r => {
                userHeaderInfo[6] = language;
            });
        }
    }

    setFlag(language) {
        if (language === 'fr') {
            this.selectedLanguageFlag = "./assets/img/flags/fr.png";
        }
        else
            if (language === 'en') {
                this.selectedLanguageFlag = "./assets/img/flags/gb.png";
            }
            else if (language === 'es') {
                this.selectedLanguageFlag = "./assets/img/flags/es.png";
            }
            else if (language === 'it') {
                this.selectedLanguageFlag = "./assets/img/flags/it.png";
            }
            else if (language === 'de') {
                this.selectedLanguageFlag = "./assets/img/flags/de.png";
            }
            else if (language === 'nl') {
                this.selectedLanguageFlag = "./assets/img/flags/nl.png";
            }
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        }
        else
            this.toggleClass = 'ft-maximize'
    }

    ToggleCompact() {
        if (this.toggleCompact === 'ft-sidebar') {
            this.toggleCompact = 'ft-align-justify';
        }
        else
            this.toggleCompact = 'ft-sidebar'

        $('.nav-toggle').trigger('click');
        if ($(this).prop('checked') === true) {
            $('.app-sidebar').trigger('mouseleave');
        }


    }

    // On place un timestamp sur la dernière date/heure de clic sur la cloche de notifications utilisateur
    NotifClicked() {
        if (this.userUI) this.userService.marquerDernierAccesClocheNotif(userHeaderInfo[1], userHeaderInfo[2]).then(r => { });
        else
            this.adminService.marquerDernierAccesClocheNotifAdmin(userHeaderInfo[1]).then(r => { });
    }

    titleActionsClass(){
        return this.userUI ? 'title-actions' : 'title-actions-admin'
    }

}
