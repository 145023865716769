import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { titreNoeudTaxoReference, cssNoeudTaxoReference } from 'app/services/commons';
import { CriteresRecherche } from 'app/services/reference-usage.service';
import { DxTreeListComponent, DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-taxo',
  templateUrl: './taxo.component.html',
  styleUrls: ['./taxo.component.scss']
})
export class TaxoComponent implements OnInit {

  private dataSource_: any
  taxoDataSource: any
  referenceUsageDataSource: any

  public get dataSource(){
    return this.dataSource_
  }
  @Input() public set dataSource(data: any){
    if(data){
      let asMap = new Map()
      data.forEach(element => {
        asMap.set(element.node, element.refs)
      });
      this.dataSource_ = asMap
      this.taxoDataSource = Array.from(asMap.keys())
    } else {
      this.dataSource_ = []
    }
  }  

  @ViewChild('taxoTreeList', {static: true}) taxoTreeList: DxTreeListComponent;
  @ViewChild('refusageGrid') refusageGrid: DxDataGridComponent

  titreNoeud: any = titreNoeudTaxoReference;
  cssNoeud: any = cssNoeudTaxoReference;

  lastSelectedNode: any = undefined

  constructor() { }

  ngOnInit(): void {
  }

  taxoTreeList_itemSelectionChanged(e: any) {
    if (this.taxoTreeList.instance.getSelectedRowsData().length == 0) return;
    this.lastSelectedNode = this.taxoTreeList.instance.getSelectedRowsData()[0];

    this.referenceUsageDataSource = this.dataSource.get(e.selectedRowsData[0])
  }

  taxoTreeList_onToolbarPreparing(e: any){}

}
