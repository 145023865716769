export const eeStatuses: any = [
    {
      "text": "AE",
      "value": 1
    },
    {
      "text": "C",
      "value": 2
    },
    {
      "text": "NC",
      "value": 3
    },
    {
      "text": "SO",
      "value": 4
    },
    {
      "text": "AV",
      "value": 5
    }
]

export const labelsByStatus: any = {
    1: "Statut : à évaluer",
    2: "Statut : conforme",
    3: "Statut : non conforme",
    4: "Statut : sans objet",
    5: "Statut : à vérifier",
    10: "Rien à évaluer (informatif)",
    '': "Sélectionner texte & exigence"
}

export const shortStatusesByStatus: any = {
  1: "AE",
  2: "C",
  3: "NC",
  4: "SO",
  5: "AV",
  10: "I",
  0: "I"
}


export const statusByLabelStatus: any = {
  "AE": 1,
  "C": 2,
  "NC": 3,
  "SO": 4,
  "AV": 5,
  "I": 10
}

export const eeCssByStatus: any = {
    1: "z_aevaluer",
    2: "z_conforme",
    3: "z_nonconforme",
    4: "z_sansobjet",
    5: "z_averifier",
    10: "z_informatif"
}

export function informativeStatus(status: number) {
  return status == 10 || status == 0
}