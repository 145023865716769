
<dx-drop-down-box #dropBox 
                [dropDownOptions]="{width:500 }"
                [deferRendering]="true"
                [dataSource]="respDataSource"
                [(value)]="selectedResponsable"
                valueExpr="id"
                [displayExpr]="gridBox_displayExpr"
                placeholder="Choisir un responsable"
                [showClearButton]="true"
                (onValueChanged)="dropdown_syncGridSelection($event)"  >
  
                <div *dxTemplate="let data of 'content'">
                  <dx-data-grid #respGrid  id="respGrid"  height="380px" 
                        [dataSource]="respDataSource"
                        
                       
                        [wordWrapEnabled]="true"
                        [columnAutoWidth]="true"
                        [cacheEnabled]="false"     
                        [hoverStateEnabled]="true"
  
                        [(selectedRowKeys)]="gridSelectedRowKeys"
                        (onSelectionChanged)="dropdown_gridItemSelectionChanged($event)" 
                        (onContentReady)="$event.component.selectRowsByIndexes(treeBoxValue)" >
                    
                        <dxo-scrolling mode="standard">   </dxo-scrolling>
                    
                        <dxo-remote-operations 
                            [filtering]="false" 
                            [paging]="false" 
                            [sorting]="true" 
                            [grouping]="false"></dxo-remote-operations>
                          <dxo-paging [pageSize]="7" [enabled]="true"></dxo-paging>
                          <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[ 7, 15, 30]"></dxo-pager>
  
                        <dxo-search-panel [visible]="true"></dxo-search-panel>
                        <dxo-selection mode="single"  ></dxo-selection>
  

                        <dxi-column dataField="prenom" caption="Prénom" [allowSorting]="true" ></dxi-column>
                        <dxi-column dataField="nom" caption="Nom" [allowSorting]="true" ></dxi-column>
                        <dxi-column dataField="service" caption="Service" [allowSorting]="true" ></dxi-column>
                        <dxi-column dataField="email" caption="EMail" [allowSorting]="true" ></dxi-column>
                     
                       </dx-data-grid>
                </div>
            </dx-drop-down-box>
        
