<div class="progress-info">
    {{message}}
</div>
<dx-progress-bar #progressBar
    id="progress-bar-status"
     width="100%"
    [class.complete]="progressBar.value >= 100"
    [min]="0"
    [max]="100"
    [statusFormat]="format"
    [value]="value">
</dx-progress-bar>