<div class="card-body">
    <div class="card-block" style="height:100%">

      <table style="width:100%">
        <tr>
          <td>
            <table id="leftEditDetails" *ngIf="currentUsage" style="width:100%">
              <tr style="vertical-align:top !important; font-weight: 600;">
                <td style="width:100px;font-variant: small-caps">{{'common-term*Titre de la référence'|translate}}</td>
                <td>&nbsp;</td>
                <td>{{currentUsage.nomReference}}</td>
              </tr>
              <tr style="vertical-align:top !important">
                <td style="width:100px;font-variant: small-caps" class="dv-labels">{{'common-term*Mots-clefs'|translate}}</td>
                <td>&nbsp;</td>
                <td>{{currentUsage.motsClefsEnsemble}}</td>
              </tr>
              <tr *ngIf="currentStatut" style="vertical-align:top !important">
                <td style="width:100px;font-variant: small-caps" class="dv-labels">{{'common-term*Dernier évènement'|translate}}</td>
                <td>&nbsp;</td>
                <td> {{currentStatut}}</td>
              </tr>
              <tr *ngIf="currentUsage.resume"  style="vertical-align:top !important">
                <td style="width:100px;font-variant: small-caps" class="dv-labels">{{'common-term*Résumé'|translate}}</td>
                <td>&nbsp;</td>
                <td innerHTML="{{currentUsage.resume}}"></td>
              </tr>
              <tr *ngIf="commentaireContent" style="vertical-align:top !important">
                <td style="width:100px;font-variant: small-caps" class="dv-labels">{{'common-term*Commentaire'|translate}}</td>
                <td>&nbsp;</td>
                <td innerHTML="{{commentaireContent}}"></td>
              </tr>
              <tr *ngIf="currentExigence&&currentExigence.titreComplet" style="vertical-align:top !important">
                <td style="width:100px;font-variant: small-caps" class="dv-labels">{{'common-term*Titre de l\'exigence'|translate}}</td>
                <td>&nbsp;</td>
                <td>{{currentExigence.titreComplet}}</td>
              </tr>
            </table>
          </td>
          <td id="rightEditDetails">
              <table *ngIf="currentUsage" style="width:500px">
                  <tr style="vertical-align:top !important">
                    <td><span class="{{cssNoeud[1]}}">{{titreNoeud[1]}}</span> {{currentUsage.nomVeille}}</td>
                  </tr>
                  <tr style="vertical-align:top !important">
                    <td><span class="{{cssNoeud[2]}}">{{titreNoeud[2]}}</span> {{currentUsage.nomThematique}}</td>
                  </tr>
                  <tr style="vertical-align:top !important">
                    <td><span class="{{cssNoeud[3]}}">{{titreNoeud[3]}}</span> {{currentUsage.nomSection}}</td>
                  </tr>
                  <tr *ngIf="currentUsage.nomDomaine" style="vertical-align:top !important">
                    <td><span class="{{cssNoeud[4]}}">{{titreNoeud[4]}}</span> {{currentUsage.nomDomaine}}</td>
                  </tr>
                  <tr *ngIf="currentUsage.nomSousDomaine" style="vertical-align:top !important">
                    <td><span class="{{cssNoeud[5]}}">{{titreNoeud[5]}}</span> {{currentUsage.nomSousDomaine}}</td>
                  </tr>
                </table> 
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div id="contentTitle" class="card-title dx-toolbar-label">{{'common-term*Contenu'|translate}}</div>

  <div class="card-body">
    <div id="exContent" class="card-block dx-htmleditor-content">

        <dx-scroll-view #scrollView id="scrollview" [scrollByContent]="true" [scrollByThumb]="true"
            [showScrollbar]="'always'">
            <div [innerHTML]="currentExigence.contenu"></div>
        </dx-scroll-view>

    </div>
  </div>
