<div class="dx-fieldset">
    <div class="dx-field">
        <div *ngIf= "checkSelection()" class="dx-field-label"> <p class="card-text">{{nomGroupe}}</p></div>
        <div class="dx-field-value">
          <dx-drop-down-box #dropBox
                [dataSource]="groupeDataSource"
                [(value)]="selectedGroupe"
                valueExpr="id"
                [displayExpr]="gridBox_displayExpr"
                [placeholder]="'groupe-selecteur-nomGroupe*choisir-un-groupe'|translate"
                [showClearButton]="true"
                (onValueChanged)="dropdown_syncGridSelection($event)"
                >
  
                <div *dxTemplate="let data of 'content'">
                  <dx-data-grid #groupeGrid  id="groupeGrid"  height="400px"
                        [dataSource]="groupeDataSource"
                        [(selectedRowKeys)]="gridSelectedRowKeys"
                        (onSelectionChanged)="dropdown_gridItemSelectionChanged($event)"
                        (onContentReady)="$event.component.selectRowsByIndexes(treeBoxValue)"
                        [showCheckBoxesMode]
                        [wordWrapEnabled]="true"
                        [columnAutoWidth]="true"
                        [selection]="selection"
                        cacheEnabled="true"
                        [hoverStateEnabled]="true"
                        >
  
                        <dxo-scrolling mode="standard">   </dxo-scrolling>
  
                        <dxo-remote-operations
                            [filtering]="true"
                            [paging]="true"
                            [sorting]="true"
                            [grouping]="false"></dxo-remote-operations>
                          <dxo-paging [pageSize]="6" [enabled]="true"></dxo-paging>
                          <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[ 10, 20, 30]"></dxo-pager>
  
                        <dxo-search-panel [visible]="true"></dxo-search-panel>
                        <dxo-selection mode="single"  ></dxo-selection>
  
                        <dxi-column dataField="nom" [caption]="'clients-field-label*nom'|translate" [allowSearch]="true" [allowSorting]="true" ></dxi-column>
  
                       </dx-data-grid>
                </div>
            </dx-drop-down-box>
        </div>
    </div>
  </div>
  