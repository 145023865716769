<div class="statutsfilter">
    <span style="margin-top: 5px" class="auditTitle">&nbsp;&nbsp;{{'statut-selector-label*Statuts'|translate}}</span>
    <dx-drop-down-box #dropBoxStatut
      [hint]="'statut-selector-hint*Filtrer-statut'|translate"
      [deferRendering]="true"
      [dataSource]="statutFilterDataSource"
      valueExpr="value" 
      displayExpr="label"
      width="250"
      [(value)]="value"
      [showClearButton]="false"
      (onValueChanged)="onStatutChanged($event)">
      <div *dxTemplate="let data of 'content'">
        <dx-data-grid
          [showColumnHeaders]="false"
          [dataSource]="statutFilterDataSource"
          [columns]="['label']"
          [selection]="{ mode: 'multiple' }"
          [hoverStateEnabled]="true"
          [filterRow]="{ visible: false }"
          [height]="280"
          [(selectedRowKeys)]="value"></dx-data-grid>
          </div>
    </dx-drop-down-box>  
</div>


