
<div class="{{centrationClass(statut)}}">
    <span *ngIf="!minimal" class="dx-form-group-caption badge badge-pill badge-black  mr-1 mt-1 fonteblanche">{{labelStatus(statut)}}</span>
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    <div class="adjuststatutarea">
      <dx-radio-group layout="horizontal" [dataSource]="statuses"
          displayExpr="text" valueExpr="value" [(value)]="statut" (onValueChanged)="emit($event)">
      </dx-radio-group>
    </div>
  </div>
