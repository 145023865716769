<as-split [disabled]="false" direction="horizontal">
    <as-split-area size="40">

      <div class="card">

        <div class="card-header">
          <h4 class="card-title">{{'applicables-h4*Taxonomie'|translate}}</h4>
        </div>
        <div class="card-body">
          <div class="card-block">

            <dx-tree-list #taxoTreeList id="taxoTreeList" [dataSource]="taxoDataSource"
              [remoteOperations]="{ filtering: false }" [wordWrapEnabled]="true" [columnAutoWidth]="true"
               hasItemsExpr="hasChildren" dataStructure="plain" [rowAlternationEnabled]="false"
               parentIdExpr="parentId" [autoExpandAll]="false"
              (onSelectionChanged)="taxoTreeList_itemSelectionChanged($event)" 
              (onToolbarPreparing)="taxoTreeList_onToolbarPreparing($event)" [showRowLines]="true"
              [showBorders]="false" [height]="'min-content'">
              <dxo-load-panel [enabled]="true"></dxo-load-panel> 
              <dxo-paging [enabled]="true" [pageSize]="12"> </dxo-paging>
              <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[12, 24, 50, 100]"></dxo-pager>
              <dxo-scrolling mode="standard"></dxo-scrolling>

              <dxo-remote-operations [filtering]="false" [sorting]="false" [grouping]="false">
              </dxo-remote-operations>
              <dxo-search-panel [visible]="true"></dxo-search-panel>
              <dxo-selection mode="single" [recursive]="true"></dxo-selection>

              <dxi-column dataField="level" [caption]="'applicables-column-caption*Type'|translate" width="120px" [minWidth]="100" [allowFiltering]="false"
                [allowEditing]="false" [allowSorting]="false" cellTemplate="typeTemplate" alignment="left">
              </dxi-column>
              <dxi-column dataField="label" [caption]="'applicables-column-caption*Label'|translate" [allowSorting]="false"></dxi-column>
              

              <div *dxTemplate="let options of 'typeTemplate'">
                  <span class="{{cssNoeud[options.data.level]}}">{{titreNoeud[options.data.level]}}</span>
               </div>

            </dx-tree-list>
          </div>
        </div>
      </div>


    </as-split-area>

    <as-split-area size="60">

      <div class="card">
        <div class="card-header">
          <h4 class="card-title">{{'applicables-h4*Textes'|translate}}</h4> <div *ngIf="lastSelectedNode"class="numUsages"><span class="badge badge-pill badge-secondary">{{referenceUsageDataSource.length}}</span></div> 
        </div>
        <div class="card-body">
          <div class="card-block">

           <dx-data-grid #refusageGrid id="refusageGrid" [(dataSource)]="referenceUsageDataSource" 
              [hoverStateEnabled]="true" [allowColumnResizing]="true" columnResizingMode="widget">

              <dxo-editing mode="cell" [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="true"></dxo-editing>
              <dxo-load-panel [enabled]="true"></dxo-load-panel> 
              <dxo-remote-operations [filtering]="true" [paging]="true" [sorting]="true" [grouping]="false">
              </dxo-remote-operations>
              <dxo-paging [pageSize]="15" [enabled]="true"></dxo-paging>
              <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[15, 50, 100, 200]"></dxo-pager>
              <dxo-selection mode="single"></dxo-selection>

              <dxi-column [allowEditing]="true" cssClass="align-cell-vertical" width="40"  dataType="boolean" dataField="dejaLu" [caption]="'applicables-column-caption*Vu'|translate" [allowSorting]="false" ></dxi-column>
              <dxi-column [allowEditing]="false" cssClass="align-cell-vertical" dataField="nomReference" [caption]="'applicables-column-caption*Nom'|translate" ></dxi-column>
              <dxi-column [allowEditing]="false"cssClass="align-cell-vertical" dataField="motsClefsEnsemble" [caption]="'applicables-column-caption*Mots-clefs'|translate" [allowSearch]="false" [allowSorting]="false"></dxi-column>
             
             <dxi-column [allowEditing]="false" cssClass="align-cell-vertical" dataField="url" [caption]="'applicables-column-caption*Url'|translate" width="40" [allowSorting]="false" cellTemplate="cellTemplate"></dxi-column> 
             <div *dxTemplate="let data of 'cellTemplate'"><a target="_blank" [href]="data.data.url"><i class="fal fa-link fa-flip-vertical grey-link"></i></a></div>

            </dx-data-grid> 
          </div>
        </div>
      </div> 

    </as-split-area>

  </as-split>
