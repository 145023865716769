<div class="collapsibleContainer" [ngClass]="disabledClassIf()">
    <div (click)="showPanelOrNot($event)" class="headerPanel" [ngClass]="disabledClassIf()">
        <div class="titleNExtraHtml">
            <div class="titleCollapse {{class}}">{{title}}</div>
            <div [innerHTML]="extraHtml"></div>
        </div>
        <div *ngIf="!disabled && show"><i class="fa fa-chevron-up" aria-hidden="true"></i></div>
        <div *ngIf="!disabled && !show"><i class="fa fa-chevron-down" aria-hidden="true"></i></div>
    </div>
    <div *ngIf="!disabled && show" class="openedHeader"><hr/></div>
    <div [ngClass]="showOrNotClass()">  
    <!-- <div *ngIf="show">  -->
        <ng-content> </ng-content> 
    </div>
</div>