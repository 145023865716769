import { Component, Injectable, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CompliantModel } from './compliant.model'
import { CommonComponent } from 'app/shared/common/common.component';
import { NotCompliantModel } from '../not-compliant/not-compliant.model';
import { ToCheckModel } from '../to-check/to-check.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ee-compliant',
  templateUrl: './compliant.component.html',
  styleUrls: ['./compliant.component.scss']
})

@Injectable()
export class CompliantComponent extends CommonComponent implements OnInit {

  @Input() @Output('model') model: CompliantModel 
 // @Input() currentSite: any
  @Input('statut') statut: number
  @Input('initialStatut') initialStatut: number 
  @Input('ncModel') ncModel: NotCompliantModel
  @Input('avModel') avModel: ToCheckModel
  @Output('onOpportunity') event_: EventEmitter<any> = new EventEmitter<any>();

  constructor(public translate: TranslateService) {
    super(translate) 
   }

  ngOnInit(): void {
    super.ngOnInit()
    if(this.swithInitialNCAVToC()){
      if(this.initialStatut == 3){
        this.model.dateConstat = this.ncModel.dateConstat
        this.model.actions = this.ncModel.actions
        this.model.notes = this.ncModel.notes
        this.model.montant = this.ncModel.montant
        this.model.moyens = this.ncModel.moyens
        this.model.service = this.ncModel.service
        this.model.lastUserNCorAV = this.ncModel.lastUserStatut
        this.model.nomResponsable = this.ncModel.nomResponsable
      } else if(this.initialStatut == 5){
        this.model.dateConstat = this.avModel.dateConstat
        this.model.actions = this.avModel.actions
        this.model.notes = this.avModel.notes
        this.model.montant = this.avModel.montant
        this.model.moyens = this.avModel.moyens
        this.model.service = this.avModel.service
        this.model.lastUserNCorAV = this.ncModel.lastUserStatut
        this.model.nomResponsable = this.ncModel.nomResponsable
      }
    }
  }

  onDataFieldChange(e: any, form: any){
    form[e.field] = e.value
  }

  // Statuses according to precedant status
  swithInitialNCAVToC(){
    return (this.initialStatut == 3 && this.statut == 2) || (this.initialStatut == 5 && this.statut == 2)
  }

  stillC(){
    return this.initialStatut == 2 && this.statut == 2
  }

  // Lifting gap
  isGapLeft(){
    if(this.swithInitialNCAVToC() && this.model.dateResolution == undefined){
      this.model.dateResolution = new Date()
    }
    return this.swithInitialNCAVToC() || (this.model.dateResolution !== undefined && this.model.dateResolution !== null)
  }

  liftingGapLabel(){
    let res: string = ''
    switch(this.initialStatut){
      case 3: res = 'Non conformité statuée'
        break;
      case 5: res = 'A vérifier statué'
        break;
      default:
        break;
    }
    return res
  }

  responsableDefined(){
    return this.model.nomResponsable != undefined && this.model.nomResponsable != null && this.model.nomResponsable != ""
  }

  // Revision
  isRevised(){
    return this.model.dateRevision !== undefined && this.model.dateRevision !== null
  }

  canRevise() {
    return this.stillC() 
  }

  manageRevision(e: any){
    this.model.dateRevision = new Date()
    // For user Action
    this.event_.emit(e)
  }

  // Opportuniy
  canDoOpportunity(){
    return this.iso && !this.model.opportuniteFlag && !this.model.opportunityRealized 
  }

  canNotDoOpportunity(){
    return this.iso && this.opportunityEnabled() && !this.canRealizeOpportunity() && !this.model.opportunityRealized
  }

  opportunityEnabled(){
    return this.iso && (this.model.opportuniteFlag || this.model.opportunityRealized)
  }

  canRealizeOpportunity(){
    return this.iso && this.model.initialOpportuniteFlag && !this.model.opportunityRealized
  }

  isOpportunityRealized(){
    return this.model.opportunityRealized
  }

  setOpportunityFlag(e: any) {
    this.model.opportuniteFlag = true
    this.model.dateOuvertureOpportunite = new Date()
    // Action
    e.opportunityCreated = true
    this.event_.emit(e)
  }

  unSetOpprtunityFlag(e: any){
    this.model.opportuniteFlag = false
    // Action
    e.opportunityCanceled = true
    this.event_.emit(e)
  }

  priorityOptions = {
    items: [0,1,2,3]
  }

  onOpportunityRealized(e: any){
    this.model.opportunityRealized = true
    this.model.dateRealisationOpportunite = new Date()
    // Action
    e.opportunityRealized = true
    this.event_.emit(e)
  }

  // Specific components with no model binding
  onResponsableOpportuniteUpdated(e: any){
    if(e !== undefined){
    this.model.responsableOpportunite = e.id
    } else {
      this.model.responsableOpportunite = 0
    }
  }

  onServiceUpdated(e: any){
    // e is thrown undefined when we clear the field
    if(e !== undefined){
      this.model.service = e.id
    } else {
      this.model.service = 0
    }
  }

  onServiceOpportunityUpdated(e: any){
    // e is thrown undefined when we clear the field
    if(e !== undefined){
      this.model.serviceOpportunite = e.id
    } else {
      this.model.serviceOpportunite = 0
    }
  }

  // User actions
  hasLeveeEcartActions(){
    return this.model.lastUserNCorAV != undefined
  }

  hasLeveeEcartUser(){
    return this.model.lastUserLeveeEcart != undefined
  }

  hasOpportunityAction(){
    return this.model.lastUserOpportunity != undefined
  }

  hasOppRealizedAction(){
    return this.model.lastUserOpportunityRealized != undefined
  }

}
