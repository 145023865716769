<dx-tab-panel #tabPanel height="90%">
    <!-- 
    <dxi-item title="Texte source">
      <div class="dx-card responsive-paddings">
        <dx-text-area placeholder="Copier coller un texte en français dans cette zone et cliquer sur Analyser"
        [height]="500"
        [(value)]="plainTextValue"
        >
    </dx-text-area>
    <br><br>
    <dx-button text="Analyser" (onClick)="analyser_clicked()"></dx-button>
    </div>
    </dxi-item>
-->

    <dxi-item title="Syntagmes">

        <dx-data-grid #snGrid id="snGrid" [dataSource]="snDataSource" [wordWrapEnabled]="true" [columnAutoWidth]="true"
            [showRowLines]="true" [showBorders]="true" width="100%" height="100%"
            (onCellPrepared)="snGrid_onCellPrepared($event)" [columnAutoWidth]="true" columnResizingMode="nextColumn"
            [allowColumnResizing]="true" [hoverStateEnabled]="true"
            (onSelectionChanged)="snGrid_itemSelectionChanged($event)"
            (onExporting)="onExporting($event, 'syntagmes', 'syntagmes')">
            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Chercher..."></dxo-search-panel>
            <dxo-state-storing [enabled]="false" type="localStorage" storageKey="xtract_syntagmes"></dxo-state-storing>
            <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
            <dxo-remote-operations [filtering]="false" [paging]="false"> </dxo-remote-operations>
            <dxo-export [enabled]="true" fileName="syntagmes" [allowExportSelectedData]="true"></dxo-export>
            <dxo-paging [pageSize]="50" [enabled]="false"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50,100]"></dxo-pager>
            <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple"></dxo-selection>
            <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true"></dxo-editing>


            <dxi-column alignment="center" minWidth="140px" width="500px" cssClass="align-cell-vertical"
                dataType="string" dataField="proposition" caption="Proposition éditable"
                [allowEditing]="true"></dxi-column>
            <dxi-column alignment="center" minWidth="140px" width="300px" cssClass="align-cell-vertical"
                dataType="string" dataField="lexforms" caption="Formes lexicales" [visible]="false"
                [allowEditing]="false"></dxi-column>
            <dxi-column alignment="center" minWidth="140px" width="300px" cssClass="align-cell-vertical"
                dataType="string" dataField="lemmaform" caption="Forme lemmatisée" [visible]="false"
                [allowEditing]="false"></dxi-column>
            <dxi-column alignment="center" minWidth="140px" width="300px" cssClass="align-cell-vertical"
                dataType="string" dataField="pattern" caption="Motif" [visible]="false"
                [allowEditing]="false"></dxi-column>

            <dxi-column alignment="center" minWidth="80px" width="140px" cssClass="align-cell-vertical"
                dataType="number" dataField="noccs" caption="Occurrences" [allowEditing]="false"> </dxi-column>
            <dxi-column alignment="center" minWidth="80px" width="140px" cssClass="align-cell-vertical"
                dataType="number" dataField="freq" caption="Fréq" [allowEditing]="false"> </dxi-column>
            <dxi-column alignment="center" minWidth="80px" width="140px" cssClass="align-cell-vertical"
                dataType="number" dataField="pertinence" caption="Pertinence" [allowEditing]="false"> </dxi-column>
            <dxi-column alignment="center" minWidth="80px" width="140px " cssClass="align-cell-vertical"
                dataType="number" dataField="score" caption="Score" [allowEditing]="false"> </dxi-column>
            <dxi-column alignment="center" minWidth="80px" width="100px " cssClass="align-cell-vertical"
                dataType="number" dataField="length" caption="Long." [allowEditing]="false"> </dxi-column>

            <dxi-column alignment="center" minWidth="80px" width="100px " cssClass="align-cell-vertical"
                dataType="string" dataField="value" caption="Type" [visible]="false" [allowEditing]="false">
            </dxi-column>


        </dx-data-grid>


    </dxi-item>


    <dxi-item title="Résumé">
        <dx-scroll-view scrollByContent="true" scrollByThumb="true">
            <p>Régler les bornes de la réglette pour filtrer les phrases selon leur poids</p>
            <dx-range-slider [activeStateEnabled]="false" [min]="minSentScore" [max]="maxSentScore"
                [(start)]="startSentScore" [(end)]="endSentScore" (onValueChanged)="sliderValueChanged($event)"
                [tooltip]="tooltipRS"></dx-range-slider>


            <dx-html-editor [value]="resume">
            </dx-html-editor>

        </dx-scroll-view>
    </dxi-item>



    <dxi-item title="Verbes d'action">
        <dx-scroll-view scrollByContent="true" scrollByThumb="true">
            <dx-pie-chart id="pie" type="doughnut" title="Répartition des verbes d'action" palette="Soft Pastel"
                [dataSource]="verbsByAction">
                <dxo-size [height]="600" [width]="600">
                </dxo-size>
                <dxi-series argumentField="value" valueField="count">>
                    <dxo-label [visible]="true">
                        <dxo-connector [visible]="true"></dxo-connector>
                    </dxo-label>
                </dxi-series>
                <dxo-export [enabled]="true"></dxo-export>
                <dxo-legend [margin]="0" horizontalAlignment="right" verticalAlignment="top"></dxo-legend>
                <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip">
                </dxo-tooltip>
            </dx-pie-chart>

            <br>
            <dx-data-grid #snGrid id="snGrid" [dataSource]="verbsByAction" [wordWrapEnabled]="true"
                [columnAutoWidth]="true" [showRowLines]="true" [showBorders]="true" width="100%" height="300"
                (onCellPrepared)="snGrid_onCellPrepared($event)">

                <dxo-search-panel [visible]="true" [width]="240" placeholder="Chercher..."></dxo-search-panel>


                <dxo-header-filter [visible]="true"></dxo-header-filter>

                <dxo-remote-operations [filtering]="false" [paging]="false"> </dxo-remote-operations>

                <dxi-column alignment="center" minWidth="140px" width="300px" cssClass="align-cell-vertical"
                    dataType="string" dataField="value" caption="Valeur" [allowEditing]="false"></dxi-column>
                <dxi-column alignment="center" minWidth="140px" width="300px" cssClass="align-cell-vertical"
                    dataType="string" dataField="occurrences" caption="Formes lexicales"
                    [allowEditing]="false"></dxi-column>

            </dx-data-grid>

        </dx-scroll-view>
    </dxi-item>


</dx-tab-panel>