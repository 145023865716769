/** Date editor */
let readOnlyDateOptions = {readOnly: true, elementAttr: {style: "max-width: 100px"}, dateSerializationFormat: "yyyy-MM-dd"}
let dateOptions = {elementAttr: {style: "max-width: 345px"}, dateSerializationFormat: "yyyy-MM-dd hh:mm:ss"}
let initDateOptions = {elementAttr: {style: "max-width: 345px"}, dateSerializationFormat: "yyyy-MM-dd hh:mm:ss", value: new Date()}
let futureDateOptions = { dateSerializationFormat:'yyyy-MM-dd', min: new Date() }
let pastDateOptions = { dateSerializationFormat:'yyyy-MM-dd', max: new Date() }

/** Textarea */
let textAreaOptions = { height: "55px", autoResizeEnabled: false }
let bigTextAreaOptions = { height: "75px", autoResizeEnabled: false }
/** Text box */
let readonlyText = { disabled: true }

/** Select boxes */
let fourItemsOptions = {
    items: [0, 1, 2, 3]
}

/** Number boxes */
let readOnlyNumberOptions = { disabled: true }
let numberOptions = { showSpinButtons: true }
let percentNumberOptions = { min: 0, max: 1, format: "##0 %", step: 0.01, showSpinButtons: true }
let amountNumberOptions = { min: 0, format: "###,###,##0 €", step: 1, showSpinButtons: true }

/** HTML editors */
let dxhtmlToolbarOptions: any = {
    items: [
        //  {widget: "dxButton",  options: { text: 'test color', onClick: function(e) { console.log(e); console.log(this.exigenceVersionForm);} }},
        { name: "undo" },
        { name: "redo" },
        { name: "clear", options: { icon: "clear", type: "danger" } },
        { name: "size", acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'] },
        { name: "font", acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'] },
        { name: "separator" },
        { name: "bold" },
        { name: "italic" },
        { name: "strike" },
        { name: "underline" },
        { name: "separator" },
        { name: "alignCenter" },
        { name: "alignRight" },
        { name: "alignJustify" },
        { name: "orderedList" },
        { name: "bulletList" },
        { name: "separator" },
        //   {name: "header", formatValues:[false, 1, 2, 3, 4, 5]},
        { name: "separator" },
        { name: "color" },
        { name: "background" },
        { name: "separator" },
        { name: "link" },
        { name: "separator" },
        { name: "insertTable" },
        { name: "deleteTable" },
        { name: "insertRowAbove" },
        { name: "insertRowBelow" },
        { name: "deleteRow" },
        { name: "insertColumnLeft" },
        { name: "insertColumnRight" },
        { name: "deleteColumn" }]
};



let dxhtmlToolbarOptionsSansTableau: any = {
    items: [
        { name: "undo" },
        { name: "clear", options: { icon: "clear", type: "danger" } },
        { name: "size", acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'] },
        { name: "font", acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'] },
        { name: "separator" },
        { name: "bold" },
        { name: "italic" },
        { name: "strike" },
        { name: "underline" },
        { name: "separator" },
        { name: "alignLeft" },
        { name: "alignCenter" },
        { name: "alignRight" },
        { name: "alignJustify" },
        { name: "orderedList" },
        { name: "bulletList" },
        { name: "separator" },
        //   {name: "header", acceptedValues:[false, 1, 2, 3, 4, 5]},
        { name: "separator" },
        //  {name: "color"},
        //  {name: "background"},
        { name: "separator" },
        { name: "link" }]
};

let dxhtmlToolbarOptionsUltraLeger: any = {
    items: [
        { name: "undo" },
        { name: "clear", options: { icon: "clear", type: "danger" } },
        { name: "size", acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'] },
         { name: "separator" },
        { name: "bold" },
        { name: "italic" },
        { name: "underline" },
        { name: "separator" },
        { name: "orderedList" },
        { name: "bulletList" },
        { name: "separator" },
        //   {name: "header", acceptedValues:[false, 1, 2, 3, 4, 5]},
        { name: "separator" },
         {name: "color"}
]
};


export default {
    readOnlyDateOptions, dateOptions, initDateOptions, textAreaOptions, bigTextAreaOptions, readonlyText,
     fourItemsOptions, readOnlyNumberOptions, numberOptions, percentNumberOptions,
    amountNumberOptions, dxhtmlToolbarOptions, dxhtmlToolbarOptionsSansTableau, dxhtmlToolbarOptionsUltraLeger,
    futureDateOptions, pastDateOptions

}