<div class="toEvaluate {{formAttr.class}}">&nbsp;</div>

<!-- <dx-form #cForm [(formData)]="model" (onDataFieldChange)="onDataFieldChange($event, cForm)"
         [alignItemLabels]="false" [alignItemLabelsInAllGroups]="false" labelLocation="left" [showColonAfterLabel]="false" [colCount]="'auto'" 
         [minColWidth]="768" [elementAttr]="formAttr">

         <dxi-item itemType="group"  [colSpan]="2" [colCount]="2" [visible]="model.lastUserStatut != undefined">
            <dxi-item dataField="dateConstat" dataType="date" editorType="dxDateBox" [editorOptions]="dateConstatOptions()">
                <dxo-label [text]="'exigence/form-label*Statué le'|translate"></dxo-label>
            </dxi-item>
 
            <dxi-item dataField="lastUserStatut" dataType="string" editorType="dxTextBox" [editorOptions]="readonlyText">
                <dxo-label [text]="'exigence/form-label*Par'|translate"></dxo-label>
            </dxi-item>

            <dxi-item dataField="notes" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                    [colSpan]="2">
                <dxo-label [text]="'exigence/form-label*Remarque'|translate"></dxo-label>
            </dxi-item>
        </dxi-item>

</dx-form> -->
